<template>
  <Toaster 
    position="top-right"
    :duration="3000"
    :richColors="true"
  />
  <div class="p-6 space-y-6">
    <!-- Header -->
    <!-- Header with Filters -->
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
      <h1 class="text-2xl font-bold text-gray-900">Dashboard Lead Time</h1>
      
      <!-- Filter Section -->
      <div class="flex flex-col sm:flex-row gap-4">
        <!-- Filter Type Selector -->
        <select 
          v-model="filterType"
          class="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500"
        >
          <option value="date">Filter by Date Range</option>
          <option value="month">Filter by Month</option>
        </select>

        <!-- Date Range Filter -->
        <div v-if="filterType === 'date'" class="flex gap-2">
          <input
            type="date"
            v-model="dateFilter.startDate"
            class="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500"
          />
          <input
            type="date"
            v-model="dateFilter.endDate"
            class="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500"
          />
        </div>

        <!-- Month/Year Filter -->
        <div v-else class="flex gap-2">
          <select 
            v-model="monthFilter.month"
            class="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500"
          >
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
          <select 
            v-model="monthFilter.year"
            class="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-red-500"
          >
            <option v-for="year in yearOptions" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>

        <button 
          @click="applyFilter"
          class="px-4 py-2 bg-[#B70000] text-white rounded-lg hover:bg-red-600"
        >
          Apply Filter
        </button>
      </div>

      <div class="text-lg font-medium text-gray-600">
        {{ formatDateTime(statistics.current_time) }}
      </div>
    </div>

    <!-- Key Metrics Cards -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <!-- Total Orders Card -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div class="text-sm font-medium text-gray-500 mb-1">Total Orders</div>
        <div class="text-2xl font-bold text-gray-900">{{ statistics.overall.total_orders }}</div>
        <div class="flex items-center gap-2 mt-2">
          <div class="text-sm">
            <span class="text-gray-500">Active:</span>
            <span class="font-medium text-blue-600">{{ statistics.overall.active_orders }}</span>
          </div>
          <div class="text-sm">
            <span class="text-gray-500">Completed:</span>
            <span class="font-medium text-green-600">{{ statistics.overall.completed_orders }}</span>
          </div>
        </div>
      </div>

      <!-- Completion Rate Card -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div class="text-sm font-medium text-gray-500 mb-1">Completion Rate</div>
        <div class="text-2xl font-bold text-gray-900">{{ formatPercentage(statistics.overall.completion_rate) }}%</div>
        <div class="text-sm text-gray-500 mt-2">
          Avg Lead Time: {{ formatLeadTime(statistics.overall.average_lead_time) }}
        </div>
      </div>

      <!-- Staff Utilization Card -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div class="text-sm font-medium text-gray-500 mb-1">Staff Utilization</div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="text-sm text-gray-600">Mechanics</div>
            <div class="text-lg font-semibold">
              {{ statistics.staff.mechanics.active }}
              <!-- /{{ statistics.staff.mechanics.total }} -->
            </div>
            <!-- <div class="text-xs text-gray-500">
              {{ formatPercentage(statistics.staff.mechanics.utilization) }}% Utilized
            </div> -->
            <div class="text-xs text-gray-500">
              Active
            </div>
          </div>
          <div>
            <div class="text-sm text-gray-600">Advisors</div>
            <div class="text-lg font-semibold">
              {{ statistics.staff.advisors.active }}
              <!-- /{{ statistics.staff.advisors.total }} -->
            </div>
            <!-- <div class="text-xs text-gray-500">
              {{ formatPercentage(statistics.staff.advisors.utilization) }}% Utilized
            </div> -->
            <div class="text-xs text-gray-500">
              Active
            </div>
          </div>
        </div>
      </div>

      <!-- Status Breakdown Card -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div class="text-sm font-medium text-gray-500 mb-1">Status Breakdown</div>
        <div class="space-y-2">
          <div class="flex justify-between items-center">
            <span class="text-sm text-gray-600">Not Started</span>
            <span class="font-medium">{{ statistics.overall.status_breakdown.belum_mulai }}</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="text-sm text-gray-600">In Progress</span>
            <span class="font-medium">{{ statistics.overall.status_breakdown.proses }}</span>
          </div>
          <div class="flex justify-between items-center">
            <span class="text-sm text-gray-600">Completed</span>
            <span class="font-medium">{{ statistics.overall.status_breakdown.selesai }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Service Category & Subcategory Cards -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Service Category -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <h2 class="text-lg font-medium text-gray-900 mb-4">Service Category</h2>
        <apexchart
          type="donut"
          height="300"
          :options="serviceCategoryChartOptions"
          :series="serviceCategorySeries"
        />
      </div>

      <!-- Service Subcategory -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <h2 class="text-lg font-medium text-gray-900 mb-4">Service Subcategory</h2>
        <apexchart
          type="bar"
          height="300"
          :options="serviceSubcategoryChartOptions"
          :series="serviceSubcategorySeries"
        />
      </div>
    </div>

    <!-- Charts Section -->
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <!-- Hourly Distribution Chart -->
      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <h2 class="text-lg font-medium text-gray-900 mb-8">Hourly Distribution</h2>
        <apexchart
          type="line"
          height="470"
          :options="hourlyChartOptions"
          :series="hourlyChartSeries"
        />
      </div>

      <div class="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div class="space-y-4">
          <!-- Header -->
          <div class="flex justify-between items-center">
            <h2 class="text-lg font-medium text-gray-900">Job Stop Overview (Melebihi Standar)</h2>
          </div>
          
          <!-- Standard Time Info -->
          <div class="bg-gray-50 p-3 rounded-lg">
            <h3 class="text-sm font-medium text-gray-700 mb-2">Standard Time:</h3>
            <div class="grid grid-cols-2 md:grid-cols-3 gap-2 text-sm">
              <div v-for="(time, key) in STANDARD_TIMES" :key="key" class="flex items-center gap-2">
                <div class="w-2 h-2 rounded-full bg-gray-400"></div>
                <span>{{ STOP_LABELS[key] }}: {{ time }}m</span>
              </div>
            </div>
          </div>
      
          <!-- Chart -->
          <apexchart
            type="bar"
            height="350"
            :options="jobStopsChartOptions"
            :series="jobStopsChartSeries"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import apiClient from '../config/api'
import { toast } from 'vue-sonner';
import { Toaster } from 'vue-sonner'

// State
// Updated state initialization
const statistics = ref({
  current_time: '',
  service_category: {
    maintenance: 0,
    repair: 0
  },
  service_subcategory: {
    tune_up: 0,
    tune_up_addition: 0,
    periodic_service: 0,
    periodic_service_addition: 0,
    general_repair: 0
  },
  overall: {
    total_orders: 0,
    completed_orders: 0,
    active_orders: 0,
    completion_rate: 0,
    average_lead_time: 0,
    status_breakdown: {
      belum_mulai: 0,
      proses: 0,
      selesai: 0
    },
    job_stops: {},
    average_job_stop_durations: {}
  },
  staff: {
    mechanics: { total: 0, active: 0, utilization: 0 },
    advisors: { total: 0, active: 0, utilization: 0 }
  },
  hourly_distribution: {}
})

// Add new state for filters
const filterType = ref('date')
const dateFilter = ref({
  startDate: new Date().toISOString().split('T')[0],
  endDate: new Date().toISOString().split('T')[0]
})
const monthFilter = ref({
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear()
})

// Constants
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
]

const yearOptions = computed(() => {
  const currentYear = new Date().getFullYear()
  return Array.from({length: 5}, (_, i) => currentYear - 2 + i)
})

// Helper Functions
const formatDateTime = (dateString) => {
  if (!dateString) return ''
  try {
    const date = new Date(dateString)
    return new Intl.DateTimeFormat('id-ID', {
      dateStyle: 'full',
      timeStyle: 'medium',
      timeZone: 'Asia/Jakarta'
    }).format(date)
  } catch (e) {
    return dateString
  }
}

const formatPercentage = (value) => {
  return Number(value || 0).toFixed(1)
}

// Chart Series & Options
const formatHour = (hour) => `${String(hour).padStart(2, '0')}:00`

const hourlyChartSeries = computed(() => {
  const hours = Object.entries(statistics.value.hourly_distribution || {})
    .sort(([a], [b]) => parseInt(a) - parseInt(b))
  
  return [
    {
      name: 'Service Starts',
      data: hours.map(([hour, data]) => ({
        x: formatHour(hour),
        y: data.starts || 0
      }))
    },
    {
      name: 'Completions',
      data: hours.map(([hour, data]) => ({
        x: formatHour(hour),
        y: data.completions || 0
      }))
    }
  ]
})

const hourlyChartOptions = {
  chart: {
    type: 'line',
    dropShadow: {
      enabled: true,
      color: '#000',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: { show: false }
  },
  colors: ['#4F46E5', '#10B981'],
  dataLabels: { enabled: false },
  stroke: {
    curve: 'smooth',
    width: 3
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5
    },
  },
  markers: { 
    size: 6,
    hover: {
      size: 8,
      sizeOffset: 3
    }
  },
  xaxis: {
    categories: Array.from({ length: 10 }, (_, i) => formatHour(i + 7)),
    title: { 
      text: 'Hour',
      style: {
        fontSize: '14px',
        fontWeight: 500
      }
    },
    labels: {
      formatter: (val) => val
    },
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    title: { 
      text: 'Count',
      style: {
        fontSize: '14px',
        fontWeight: 500
      }
    },
    min: 0,
    forceNiceScale: true,
    tickAmount: 5
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    floating: true,
    offsetY: -25,
    offsetX: -5
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: (val) => Math.round(val)
    },
    x: {
      formatter: (val) => {
        if (typeof val === 'string') {
          return val
        }
        return formatHour(val + 7)  // Adjust index to hour
      }
    },
    style: {
      fontSize: '12px'
    }
  }
}

const formatDuration = (minutes) => {
  if (!minutes) return '0m'
  
  // Bulatkan ke 1 desimal untuk menghindari angka yang terlalu panjang
  minutes = Math.round(minutes * 10) / 10

  if (minutes < 60) {
    return `${minutes}m`
  }
  
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.round(minutes % 60)
  
  if (remainingMinutes === 0) {
    return `${hours}h`
  }
  
  return `${hours}h ${remainingMinutes}m`
}

// Helper function untuk konversi menit ke format jam yang readable
const formatMinutesToHours = (minutes) => {
  if (!minutes) return '0h'
  if (minutes < 60) return `${minutes}m`
  
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.round(minutes % 60)
  
  if (remainingMinutes === 0) return `${hours}h`
  return `${hours}h ${remainingMinutes}m`
}

// Update jobStopsChartSeries computed property
const jobStopsChartSeries = computed(() => {
  if (!statistics.value?.overall?.standards_analysis) return []

  const data = Object.entries(statistics.value.overall.standards_analysis)
    .map(([key, value]) => ({
      name: STOP_LABELS[key],
      exceededCount: value.exceeded_count,
      avgExceededDuration: value.avg_exceeded_duration || 0,
      standardTime: STANDARD_TIMES[key],
      withinCount: value.within_count,
      totalCount: value.total_count
    }))
    .filter(item => item.exceededCount > 0)

    return [
    {
      name: 'Jumlah Kasus Melebihi',
      data: data.map(item => ({
        x: item.name,
        y: item.exceededCount,
        standardTime: item.standardTime,
        totalCount: item.totalCount,
        withinCount: item.withinCount
      }))
    },
    {
      name: 'Rata-rata Durasi',
      data: data.map(item => ({
        x: item.name,
        y: item.avgExceededDuration,
        standardTime: item.standardTime
      }))
    }
  ]
})

// Update jobStopsChartOptions dengan format durasi yang baru
// Update chart options untuk menampilkan standar time pada tooltip
const jobStopsChartOptions = {
  chart: {
    type: 'bar',
    stacked: false,
    toolbar: { show: false },
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800
    }
  },
  colors: ['#EF4444', '#F59E0B'],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      borderRadius: 4
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function(val, { seriesIndex }) {
      if (seriesIndex === 0) return Math.round(val)
      return formatDuration(val)
    },
    style: {
      fontSize: '12px'
    },
    offsetY: -10
  },
  xaxis: {
    type: 'category',
    labels: {
      style: {
        fontSize: '12px'
      },
      rotate: -45,
      maxHeight: 120
    },
    tickPlacement: 'on'
  },
  yaxis: [
    {
      title: {
        text: 'Job Stop Melampaui Standar',
        style: {
          fontSize: '13px',
          fontWeight: 600
        }
      },
      labels: {
        formatter: (val) => Math.round(val)
      }
    },
    {
      opposite: true,
      title: {
        text: 'Durasi Rata-rata',
        style: {
          fontSize: '13px',
          fontWeight: 600
        }
      },
      labels: {
        formatter: (val) => formatDuration(val)
      }
    }
  ],
  tooltip: {
    shared: true,
    intersect: false,
    custom: function({ seriesIndex, dataPointIndex, w }) {
      const series = w.config.series[seriesIndex]
      const data = series.data[dataPointIndex]
      let content = '<div class="p-3">'
      
      if (seriesIndex === 0) {
        content += `
          <div style="margin-bottom: 4px">Jumlah Kasus: ${Math.round(data.y)}</div>
          <div style="margin-bottom: 4px">Standar Waktu: ${data.standardTime} menit</div>
          <div style="margin-bottom: 4px">Total Kasus: ${data.totalCount}</div>
          <div>Dalam Standar: ${data.withinCount}</div>
        `
      } else {
        content += `
          <div style="margin-bottom: 4px">Rata-rata Durasi: ${formatDuration(data.y)}</div>
          <div>Standar Waktu: ${data.standardTime} menit</div>
        `
      }
      
      content += '</div>'
      return content
    }
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    offsetY: 20
  }
}
// Constants (tidak berubah)
const STOP_LABELS = {
  'tunggu_penerimaan': 'Tunggu Penerimaan',
  'penerimaan': 'Penerimaan',
  'tunggu_servis': 'Tunggu Servis',
  'tunggu_konfirmasi': 'Tunggu Konfirmasi',
  'tunggu_part1': 'Tunggu Part 1',
  'tunggu_part2': 'Tunggu Part 2'
}

const STANDARD_TIMES = {
  'tunggu_penerimaan': 15,
  'penerimaan': 15,
  'tunggu_servis': 15,
  'tunggu_konfirmasi': 40,
  'tunggu_part1': 45,
  'tunggu_part2': 45
}


// Computed properties for charts
const serviceCategorySeries = computed(() => [
  statistics.value.service_category?.maintenance || 0,
  statistics.value.service_category?.repair || 0,
  statistics.value.service_category?.uncategorized || 0 
])

const serviceCategoryChartOptions = {
  chart: {
    type: 'donut'
  },
  labels: ['Maintenance', 'Repair', 'Uncategorized'],
  colors: ['#4F46E5', '#10B981', '#F59E0B'],
  legend: {
    position: 'bottom'
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      // Membulatkan persentase ke 1 angka desimal
      return Math.round(val * 10) / 10 + '%'
    }
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            show: true,
            label: 'Total Orders',
            formatter: function (w) {
              const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
              return total.toLocaleString() // Format dengan thousand separator
            }
          }
        }
      }
    }
  }
}


const serviceSubcategorySeries = computed(() => [{
  name: 'Services',
  data: [
    statistics.value.service_subcategory?.tune_up || 0,
    statistics.value.service_subcategory?.tune_up_addition || 0,
    statistics.value.service_subcategory?.periodic_service || 0,
    statistics.value.service_subcategory?.periodic_service_addition || 0,
    statistics.value.service_subcategory?.general_repair || 0,
    statistics.value.service_subcategory?.oil_change || 0,
    statistics.value.service_subcategory?.uncategorized || 0
  ]
}])

const serviceSubcategoryChartOptions = {
  chart: {
    type: 'bar'
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 4,
      dataLabels: {
        position: 'bottom'
      }
    }
  },
  colors: ['#6366F1'],
  xaxis: {
    categories: [
      'Tune Up',
      'Tune Up Addition',
      'Servis Berkala',
      'Servis Berkala Addition',
      'General Repair',
      'Ganti Oli',
      'Uncategorized'
    ]
  },
  dataLabels: {
    enabled: true,
    textAnchor: 'start',
    style: {
      colors: ['#fff']
    },
    formatter: function (val) {
      return val.toFixed(0)
    },
    offsetX: 0
  },
  tooltip: {
    enabled: true,
    y: {
      formatter: function (val) {
        return val.toFixed(0) + ' orders'
      }
    }
  }
}

const formatLeadTime = (timeInHours) => {
  if (!timeInHours) return '0min'
  
  const hours = Math.floor(timeInHours)
  const minutes = Math.round((timeInHours - hours) * 60)
  
  if (hours === 0) {
    return `${minutes}min`
  } else if (minutes === 0) {
    return `${hours}h`
  } else {
    return `${hours}h ${minutes}min`
  }
}


// Update fetchStatistics to handle filters
const fetchStatistics = async () => {
  try {
    let params = {}
    
    if (filterType.value === 'date') {
      params = {
        start_date: dateFilter.value.startDate,
        end_date: dateFilter.value.endDate
      }
    } else if (filterType.value === 'month') {
      params = {
        month: parseInt(monthFilter.value.month),
        year: parseInt(monthFilter.value.year)
      }
    }

    // Log request params for debugging
    console.log('Fetching statistics with params:', params)

    const response = await apiClient.post('/web/lead-time/statistics', {
      jsonrpc: "2.0",
      method: "call",
      params
    })
    
    if (response?.data?.result?.status === 'success') {
      toast.success('Berhasil memuat data statistik terbaru')
      statistics.value = response.data.result.data
      console.log('Statistics fetched successfully:', statistics.value)
    } else {
      console.error('Error in response:', response?.data?.result)
    }
  } catch (error) {
    console.error('Error fetching statistics:', error)
  }
}

const applyFilter = () => {
  fetchStatistics()
}

// Watch perubahan filter
watch([filterType, dateFilter, monthFilter], () => {
  fetchStatistics()
}, { deep: true })

// Lifecycle Hooks
onMounted(() => {
  fetchStatistics()
  const interval = setInterval(fetchStatistics, 300000)
  onUnmounted(() => clearInterval(interval))
})
</script>
