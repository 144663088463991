# ServiceReportDetail.vue
<template>
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border">
      <!-- Loading State -->
      <div v-if="loading" class="h-96 flex items-center justify-center">
        <div class="text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Loading report details...</p>
        </div>
      </div>

      <div v-else-if="report" class="p-6">
        <!-- Navigation Header -->
        <div class="flex justify-between items-center mb-6">
          <button 
            v-if="report.navigation.previous"
            @click="navigateToReport(report.navigation.previous.id)"
            class="flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <ChevronLeftIcon class="h-5 w-5" />
            <div class="text-sm text-left">
              <div class="font-medium">Previous Report</div>
              <div class="text-gray-500">{{ report.navigation.previous.service_id }}</div>
            </div>
          </button>
          <div v-else class="w-24"></div>

          <!-- Back to List -->
          <RouterLink 
            to="/reports" 
            class="px-4 py-2 border rounded-lg hover:bg-gray-50 text-sm"
          >
            Back to List
          </RouterLink>

          <button 
            v-if="report.navigation.next"
            @click="navigateToReport(report.navigation.next.id)"
            class="flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <div class="text-sm text-right">
              <div class="font-medium">Next Report</div>
              <div class="text-gray-500">{{ report.navigation.next.service_id }}</div>
            </div>
            <ChevronRightIcon class="h-5 w-5" />
          </button>
          <div v-else class="w-24"></div>
        </div>

        <!-- Basic Info Header -->
        <div class="flex justify-between items-start mb-8">
          <div>
            <h1 class="text-2xl font-bold text-gray-900 mb-1">
              {{ report.basic_info.service_id }}
            </h1>
            <div class="flex items-center gap-3 text-sm text-gray-500">
              <span>Created: {{ formatDate(report.basic_info.create_date) }}</span>
              <span class="px-2 py-1 rounded-full text-xs font-medium"
                    :class="getStatusClass(report.basic_info.status)">
                {{ report.basic_info.status.text }}
              </span>
            </div>
          </div>
          
          <div class="text-right">
            <div class="text-sm text-gray-500 mb-1">Overall Progress</div>
            <div class="w-48">
              <div class="flex justify-between text-sm mb-1">
                <span>Progress</span>
                <span>{{ report.basic_info.progress }}%</span>
              </div>
              <div class="w-full bg-gray-200 rounded-full h-2">
                <div class="bg-blue-600 h-2 rounded-full transition-all duration-500"
                     :style="{ width: `${report.basic_info.progress}%` }">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Content Grid -->
        <div class="grid grid-cols-3 gap-6">
          <!-- Left Column - Customer & Vehicle -->
          <div class="space-y-6">
            <!-- Customer Info -->
            <div class="border rounded-lg p-4">
              <h2 class="text-lg font-medium mb-4">Customer Information</h2>
              <div class="space-y-3">
                <div>
                  <div class="text-sm text-gray-500">Name</div>
                  <div class="font-medium">{{ report.customer.name }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-500">Phone</div>
                  <div class="font-medium">{{ report.customer.phone }}</div>
                </div>
              </div>
            </div>

            <!-- Vehicle Info -->
            <div class="border rounded-lg p-4">
              <h2 class="text-lg font-medium mb-4">Vehicle Information</h2>
              <div class="space-y-3">
                <div class="grid grid-cols-2 gap-3">
                  <div>
                    <div class="text-sm text-gray-500">Brand</div>
                    <div class="font-medium">{{ report.vehicle.brand }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-500">Type</div>
                    <div class="font-medium">{{ report.vehicle.type }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-500">Plate</div>
                    <div class="font-medium">{{ report.vehicle.plate }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-500">Year</div>
                    <div class="font-medium">{{ report.vehicle.year }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-500">Color</div>
                    <div class="font-medium">{{ report.vehicle.color }}</div>
                  </div>
                  <div>
                    <div class="text-sm text-gray-500">Transmission</div>
                    <div class="font-medium">{{ report.vehicle.transmission }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Staff Info -->
            <div class="border rounded-lg p-4">
              <h2 class="text-lg font-medium mb-4">Service Staff</h2>
              <div class="space-y-3">
                <div>
                  <div class="text-sm text-gray-500">Service Advisor</div>
                  <div class="font-medium">{{ report.staff.advisors[0]?.name || '-' }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-500">Mechanic</div>
                  <div class="font-medium">{{ report.staff.mechanics || '-' }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Column - Timeline & Metrics -->
          <div class="col-span-2 space-y-6">
            <!-- Service Metrics -->
            <div class="grid grid-cols-4 gap-4">
              <div class="border rounded-lg p-4">
                <div class="text-sm text-gray-500">Total Duration</div>
                <div class="text-xl font-bold">{{ report.timeline.metrics.total_duration_text }}</div>
              </div>
              
              <div class="border rounded-lg p-4">
                <div class="text-sm text-gray-500">Active Time</div>
                <div class="text-xl font-bold">{{ report.timeline.metrics.active_duration_text }}</div>
              </div>
              
              <div class="border rounded-lg p-4">
                <div class="text-sm text-gray-500">Delay Time</div>
                <div class="text-xl font-bold" :class="{'text-red-600': report.timeline.metrics.has_delays}">
                  {{ report.timeline.metrics.delay_duration_text }}
                </div>
              </div>
              
              <div class="border rounded-lg p-4">
                <div class="text-sm text-gray-500">Status</div>
                <div class="text-xl font-bold" :class="{'text-green-600': report.timeline.metrics.is_completed}">
                  {{ report.timeline.metrics.is_completed ? 'Completed' : 'In Progress' }}
                </div>
              </div>
            </div>

            <!-- Timeline -->
            <div class="border rounded-lg p-4">
              <h2 class="text-lg font-medium mb-6">Service Timeline</h2>
              <div class="relative">
                <div class="space-y-6">
                  <div 
                    v-for="(event, index) in report.timeline.events" 
                    :key="event.type"
                    class="flex gap-4"
                  >
                    <!-- Timeline Node -->
                    <div class="flex flex-col items-center">
                      <div class="relative">
                        <div 
                          class="w-4 h-4 rounded-full border-2"
                          :class="getEventStatusClass(event.status)"
                        ></div>
                        <div v-if="index < report.timeline.events.length - 1" 
                             class="absolute top-4 left-1/2 bottom-0 w-0.5 -ml-px bg-gray-200 h-full">
                        </div>
                      </div>
                    </div>

                    <!-- Event Content -->
                    <div class="flex-1 pb-6">
                      <div class="flex items-start justify-between">
                        <div>
                          <h3 class="font-medium">{{ event.title }}</h3>
                          <p class="text-sm text-gray-500">
                            {{ formatDate(event.start_time) }}
                            <template v-if="event.end_time">
                              - {{ formatDate(event.end_time) }}
                            </template>
                          </p>
                        </div>
                        <div v-if="event.duration_text" 
                             class="text-sm px-3 py-1 rounded-full"
                             :class="getEventDurationClass(event)">
                          {{ event.duration_text }}
                          <div v-if="event.exceeded_text" class="text-red-600 text-xs mt-1">
                            {{ event.exceeded_text }}
                          </div>
                        </div>
                      </div>

                      <!-- Standard Time -->
                      <div v-if="event.standard_time" class="mt-2 text-sm text-gray-500">
                        Standard Time: {{ event.standard_time }} minutes
                      </div>

                      <!-- Notes -->
                      <div v-if="event.notes" class="mt-2 text-sm text-gray-600">
                        {{ event.notes }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Completion Info -->
            <div v-if="report.completion" class="border rounded-lg p-4">
              <h2 class="text-lg font-medium mb-4">Completion Details</h2>
              <div class="grid grid-cols-2 gap-4">
                <div>
                  <div class="text-sm text-gray-500">Estimated Completion</div>
                  <div class="font-medium">{{ formatDate(report.completion.estimated) || 'Not set' }}</div>
                </div>
                <div>
                  <div class="text-sm text-gray-500">Actual Completion</div>
                  <div class="font-medium">{{ formatDate(report.completion.actual) || 'Not completed' }}</div>
                </div>
                <div v-if="report.completion.is_delayed" class="col-span-2 text-red-600">
                  Service is delayed
                </div>
                <div v-if="report.completion.notes" class="col-span-2">
                  {{ report.completion.notes }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { 
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/vue/24/outline'
import { toast } from 'vue-sonner'
import apiClient from '../config/api'

const route = useRoute()
const router = useRouter()
const loading = ref(false)
const report = ref(null)

const fetchReportDetail = async (id) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/service-report/detail', { // Update endpoint path
      jsonrpc: "2.0",
      method: "call",
      params: {
        order_id: Number(id) // Ensure id is a number
      }
    })

    if (response?.data?.result?.status === 'success') {
      report.value = response.data.result.data
      // Debug log
      console.log('Report detail fetched:', report.value)
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to fetch report details')
    }
  } catch (error) {
    console.error('Error fetching report details:', error)
    toast.error('Failed to load report details: ' + error.message)
  } finally {
    loading.value = false
  }
}

const navigateToReport = (id) => {
  router.push(`/reports/${id}`)
}

const getStatusClass = (status) => {
  const colors = {
    completed: 'bg-green-100 text-green-800',
    in_progress: 'bg-blue-100 text-blue-800',
    delayed: 'bg-yellow-100 text-yellow-800',
    belum_mulai: 'bg-gray-100 text-gray-800'
  }
  return colors[status.code] || colors.belum_mulai
}

const getEventStatusClass = (status) => {
  const colors = {
    completed: 'border-green-500 bg-green-100',
    in_progress: 'border-blue-500 bg-blue-100',
    delayed: 'border-yellow-500 bg-yellow-100',
    default: 'border-gray-500 bg-gray-100'
  }
  return colors[status] || colors.default
}

const getEventDurationClass = (event) => {
  if (!event.duration_text) return 'bg-gray-100 text-gray-600'
  
  if (event.exceeded) {
    return 'bg-red-100 text-red-600'
  }
  
  return event.status === 'completed' 
    ? 'bg-green-100 text-green-600' 
    : 'bg-blue-100 text-blue-600'
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).format(date)
}

// Watch for route changes to reload data
watch(
  () => route.params.id,
  (newId) => {
    if (newId) {
      fetchReportDetail(newId)
    }
  }
)

// Initialize
onMounted(() => {
  if (route.params.id) {
    fetchReportDetail(route.params.id)
  }
})
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Timeline connector line style */
.timeline-connector {
  @apply absolute top-0 left-1/2 h-full w-px bg-gray-200 -ml-px;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>s