<template>
  <div class="min-h-screen flex">
    <!-- Left Side - Illustration & Info -->
    <div class="hidden lg:flex lg:w-1/2 bg-[#f4f5f5] p-12 flex-col justify-between relative overflow-hidden">
      <!-- Simplified Floating Elements - hanya 5 icon strategis -->
    <div class="absolute inset-0">
      <!-- Timer Icon - Top Right -->
      <div class="absolute top-32 right-24 w-20 h-20 bg-[#B70000]/10 rounded-lg -rotate-12 animate-float-medium">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-5 text-[#B70000]/60" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>

      <!-- Car Icon - Center Left -->
      <div class="absolute bottom-60 left-48 w-24 h-24 bg-[#B70000]/10 rounded-lg rotate-6 animate-float-fast transform -translate-y-1/2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 m-6 text-[#B70000]/60" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16v-4m4 4v-4m4 4v-4M4 20h16a2 2 0 002-2V6a2 2 0 00-2-2H4a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      </div>

      <!-- Checklist Icon - Center Right -->
      <div class="absolute top-1/2 right-12 w-16 h-16 bg-[#B70000]/10 rounded-full animate-float-medium transform -translate-y-1/2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 m-4 text-[#B70000]/60" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
        </svg>
      </div>

      <!-- Progress Circle - Bottom Left -->
      <div class="absolute bottom-32 left-20 w-12 h-12 animate-spin-slow">
        <div class="h-full w-full rounded-full border-4 border-[#B70000]/20 border-t-[#B70000]/60"></div>
      </div>

      <!-- Tools Icon - Bottom Right -->
      <div class="absolute bottom-20 right-20 w-20 h-20 bg-[#B70000]/10 rounded-lg -rotate-12 animate-float-slow">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 m-5 text-[#B70000]/60" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
      </div>
    </div>
    
      <!-- Main Content -->
      <div class="relative z-10 max-w-2xl">
        <!-- Header Section -->
        <div class="mb-8">
          <h1 class="text-4xl font-bold leading-tight mb-4">
            <span class="text-[#B70000] block">Lead Time Service</span>
            <span class="text-[#B70000] block">Analytics System</span>
          </h1>
          <div class="space-y-3">
            <p class="text-xl text-gray-700 font-medium">
              Transform Service Efficiency & Transparency
            </p>
            <p class="text-gray-600 leading-relaxed">
              Advanced tracking system that provides real-time visibility into your vehicle's service progress, ensuring transparency and efficiency at every step.
            </p>
          </div>
        </div>
    
        <!-- Features List -->
        <ul class="space-y-6">
          <!-- Feature 1: Real-time Monitoring -->
          <li class="flex items-start group transform transition-all duration-300 hover:translate-x-2">
            <div class="w-12 h-12 mr-4 flex-shrink-0 flex items-center justify-center bg-[#B70000]/10 rounded-xl group-hover:bg-[#B70000]/20 transition-colors">
              <svg class="w-6 h-6 text-[#B70000]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-gray-900 mb-1">
                Live Service Monitoring
              </h3>
              <p class="text-gray-600 leading-relaxed">
                Pantau progres servis kendaraan Anda secara real-time dengan informasi status dan waktu yang akurat di setiap tahap.
              </p>
            </div>
          </li>
    
          <!-- Feature 2: Stage Tracking -->
          <li class="flex items-start group transform transition-all duration-300 hover:translate-x-2">
            <div class="w-12 h-12 mr-4 flex-shrink-0 flex items-center justify-center bg-[#B70000]/10 rounded-xl group-hover:bg-[#B70000]/20 transition-colors">
              <svg class="w-6 h-6 text-[#B70000]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
              </svg>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-gray-900 mb-1">
                Detailed Stage Progress
              </h3>
              <p class="text-gray-600 leading-relaxed">
                Ikuti setiap milestone servis dengan sistem tracking komprehensif kami, dari proses check-in hingga selesai pengerjaan.
              </p>
            </div>
          </li>
    
          <!-- Feature 3: Accurate Estimation -->
          <li class="flex items-start group transform transition-all duration-300 hover:translate-x-2">
            <div class="w-12 h-12 mr-4 flex-shrink-0 flex items-center justify-center bg-[#B70000]/10 rounded-xl group-hover:bg-[#B70000]/20 transition-colors">
              <svg class="w-6 h-6 text-[#B70000]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <div>
              <h3 class="text-lg font-semibold text-gray-900 mb-1">
                Smart Time Estimation
              </h3>
              <p class="text-gray-600 leading-relaxed">
                Dapatkan estimasi waktu penyelesaian yang akurat berbasis data analitik, membantu Anda merencanakan aktivitas dengan lebih baik.
              </p>
            </div>
          </li>
        </ul>
    
        <!-- Trust Indicators -->
        <!-- <div class="mt-8 pt-6 border-t border-gray-200">
          <div class="flex items-center text-sm text-gray-600">
            <div class="flex items-center space-x-2">
              <svg class="w-5 h-5 text-[#B70000]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              <span class="font-medium">Bersertifikat ISO 9001:2015</span>
            </div>
            <span class="mx-2">•</span>
            <span>Layanan Servis Berstandar Internasional</span>
          </div>
        </div> -->
      </div>
    
      <!-- Bottom Text -->
      <!-- Variant 1: With Tech Badge -->
      <div class="relative z-10 space-y-3">
        <div class="flex flex-col">
          <p class="font-medium text-gray-800">
            LIME Lead Time Management System v{{ version }}
          </p>
          <p class="text-sm text-gray-600">
            © 2024 by PITCAR Service. All rights reserved.
          </p>
          <div class="inline-flex items-center px-3 py-1 mt-2 bg-gray-100 rounded-full text-sm">
            <span class="text-gray-500">Powered by</span>
            <span class="font-medium text-[#B70000] ml-1">Tim IT PITCAR
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Side - Login Form -->
    <div class="w-full lg:w-1/2 flex items-center justify-center p-8 bg-white">
      <div class="w-full max-w-md">
        <!-- Header -->
        <div class="text-center mb-10">
          <h2 class="text-3xl font-bold text-gray-900 mb-3">Welcome back! 👋</h2>
          <div class="space-y-2">
            <p class="text-lg text-gray-700 font-medium">
              PITCAR LIME Lead Time Management System
            </p>
            <p class="text-gray-600">
              Login to access your dashboard
            </p>
          </div>
        </div>

        <!-- Form -->
        <form @submit.prevent="handleLogin" class="space-y-6">
          <!-- Database Selection -->
          <div class="space-y-2">
            <label class="text-sm font-medium text-gray-700 block">Branch</label>
            <select 
              v-model="formData.database"
              required
              class="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white transition-colors"
            >
              <option value="" disabled selected>Select Branch</option>
              <option value="Pitcar1">Pitcar Service</option>
              <option value="pitcar.bodyrepair">Pitcar Body Repair</option>
              <option value="db_test">DB Testing</option>
            </select>
          </div>

          <!-- Email -->
          <div class="space-y-2">
            <label class="text-sm font-medium text-gray-700 block">Email</label>
            <input 
              type="email"
              v-model="formData.email"
              required
              placeholder="Enter your email"
              class="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            />
          </div>

          <!-- Password -->
          <div class="space-y-2">
            <label class="text-sm font-medium text-gray-700 block">Password</label>
            <div class="relative">
              <input 
                :type="showPassword ? 'text' : 'password'"
                v-model="formData.password"
                required
                placeholder="Enter your password"
                class="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
              />
              <button 
                @click="togglePassword"
                type="button"
                class="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <svg v-if="showPassword" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                <svg v-else class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                </svg>
              </button>
            </div>
          </div>

          <!-- Error Message -->
          <div v-if="error" class="bg-red-50 text-red-500 p-4 rounded-lg text-sm">
            {{ error }}
          </div>

          <!-- Submit Button -->
          <button 
            type="submit"
            :disabled="isLoading"
            class="w-full py-3 bg-[#B70000] text-white rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-blue-200 transition-colors disabled:opacity-50"
          >
            <span v-if="isLoading">Loading...</span>
            <span v-else>Login</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from '../stores/auth'
import packageJson from '../../package.json'

const router = useRouter()
const authStore = useAuthStore()

const showPassword = ref(false)
const isLoading = ref(false)
const error = ref('')
const version = packageJson.version


const formData = reactive({
  database: '',
  email: '',
  password: ''
})

const togglePassword = () => {
  showPassword.value = !showPassword.value
}

const handleLogin = async () => {
  try {
    isLoading.value = true
    error.value = ''
    
    await authStore.login(
      formData.database,
      formData.email,
      formData.password
    )
    
    router.push('/dashboard')
  } catch (err) {
    error.value = err.message || 'Login failed. Please check your credentials.'
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped>
@keyframes float {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-20px) rotate(5deg); }
}

@keyframes floatDelay {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-15px) rotate(-5deg); }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-float-delay {
  animation: floatDelay 8s ease-in-out infinite;
}
/* Animasi sama seperti sebelumnya */
@keyframes float-slow {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-20px) rotate(5deg); }
}

@keyframes float-medium {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-15px) rotate(-5deg); }
}

@keyframes float-fast {
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-10px) rotate(3deg); }
}

@keyframes spin-slow {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.animate-float-slow {
  animation: float-slow 6s ease-in-out infinite;
}

.animate-float-medium {
  animation: float-medium 5s ease-in-out infinite;
}

.animate-float-fast {
  animation: float-fast 4s ease-in-out infinite;
}

.animate-spin-slow {
  animation: spin-slow 8s linear infinite;
}

/* Tambahkan animasi baru */
@keyframes dash {
  to {
    stroke-dashoffset: 8;
  }
}

.animate-dash {
  animation: dash 1s linear infinite;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

/* Style untuk delay animasi */
.delay-300 {
  animation-delay: 300ms;
}
</style>