# ServiceReport.vue
<template>
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading State -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Loading reports...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">
        <!-- Header -->
        <div class="flex flex-col md:flex-row justify-between items-center mb-6">
          <div>
            <h1 class="text-2xl font-bold text-gray-900">Service Reports</h1>
            <p class="text-gray-500 mt-1">View and analyze service performance metrics</p>
          </div>
          
          <!-- Export Button -->
          <button 
            class="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition flex items-center gap-2"
            @click="exportReport"
          >
            <DocumentArrowDownIcon class="h-5 w-5" />
            Export Report
          </button>
        </div>

        <!-- Filter Section -->
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <!-- Date Range -->
          <div class="space-y-1">
            <label class="block text-sm font-medium text-gray-700">Date Range</label>
            <select 
              v-model="filters.dateRange"
              class="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500"
            >
              <option value="today">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="custom">Custom Range</option>
            </select>
            
            <!-- Custom Date Range Inputs -->
            <div v-if="filters.dateRange === 'custom'" class="grid grid-cols-2 gap-2 mt-2">
              <input
                type="date"
                v-model="filters.startDate"
                class="border rounded px-2 py-1"
              />
              <input
                type="date"
                v-model="filters.endDate"
                class="border rounded px-2 py-1"
              />
            </div>
          </div>

          <!-- Service Type -->
          <div>
            <label class="block text-sm font-medium text-gray-700">Service Type</label>
            <select 
              v-model="filters.serviceType"
              class="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500"
            >
              <option value="all">All Services</option>
              <option value="maintenance">Maintenance</option>
              <option value="repair">Repair</option>
            </select>
          </div>

          <!-- Status -->
          <div>
            <label class="block text-sm font-medium text-gray-700">Status</label>
            <select 
              v-model="filters.status"
              class="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500"
            >
              <option value="all">All Status</option>
              <option value="completed">Completed</option>
              <option value="in_progress">In Progress</option>
              <option value="delayed">Delayed</option>
            </select>
          </div>

          <!-- Search -->
          <div>
            <label class="block text-sm font-medium text-gray-700">Search</label>
            <div class="relative">
              <input
                v-model="searchQuery"
                type="text"
                placeholder="Service ID, Customer, or Plate Number"
                class="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              >
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
            </div>
          </div>
        </div>

        <!-- Stats Cards -->
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <!-- Service Volume -->
          <div class="bg-white p-4 rounded-lg border">
            <div class="text-sm text-gray-500">{{ stats.main_stats?.service_volume.label }}</div>
            <div class="text-2xl font-bold">{{ stats.main_stats?.service_volume.value }}</div>
            <div class="text-xs mt-1" 
                 :class="getChangeClass(stats.main_stats?.service_volume.change)">
              {{ formatChange(stats.main_stats?.service_volume.change) }}
            </div>
            <div class="mt-2 text-xs text-gray-500">
              Active: {{ stats.additional_metrics?.active_orders }}
            </div>
          </div>
          
          <!-- Average Service Time -->
          <div class="bg-white p-4 rounded-lg border">
            <div class="text-sm text-gray-500">{{ stats.main_stats?.service_time.label }}</div>
            <div class="text-2xl font-bold">{{ formatDuration(stats.main_stats?.service_time.value) }}</div>
            <div class="text-xs mt-1" 
                 :class="getChangeClass(stats.main_stats?.service_time.change)">
              {{ formatChange(stats.main_stats?.service_time.change) }}
            </div>
            <div class="mt-2 text-xs text-gray-500 grid grid-cols-2 gap-1">
              <span>Maintenance: {{ stats.additional_metrics?.service_types.maintenance || 0 }}</span>
              <span>Repair: {{ stats.additional_metrics?.service_types.repair || 0 }}</span>
            </div>
          </div>
          
          <!-- Average Wait Time -->
          <div class="bg-white p-4 rounded-lg border">
            <div class="text-sm text-gray-500">{{ stats.main_stats?.wait_time.label }}</div>
            <div class="text-2xl font-bold">{{ formatDuration(stats.main_stats?.wait_time.value) }}</div>
            <div class="text-xs mt-1" 
                 :class="getChangeClass(stats.main_stats?.wait_time.change)">
              {{ formatChange(stats.main_stats?.wait_time.change) }}
            </div>
            <div class="mt-2 text-xs text-gray-500">
              Job Stops: {{ getTotalJobStops() }}
            </div>
          </div>
          
          <!-- On Time Completion -->
          <div class="bg-white p-4 rounded-lg border">
            <div class="text-sm text-gray-500">{{ stats.main_stats?.on_time_completion.label }}</div>
            <div class="text-2xl font-bold">
              {{ formatPercentage(stats.main_stats?.on_time_completion.value) }}
            </div>
            <div class="text-xs mt-1" 
                 :class="getChangeClass(stats.main_stats?.on_time_completion.change)">
              {{ formatChange(stats.main_stats?.on_time_completion.change) }}
            </div>
            <div class="mt-2 text-xs text-gray-500">
              Completed: {{ stats.additional_metrics?.completion_details.total_completed || 0 }}
            </div>
          </div>
        </div>

        <!-- Table Section -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Service ID</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Customer</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Vehicle</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Service Type</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Start Date</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Status</th>
                <th class="text-left py-3 px-4 font-medium text-gray-700">Advisor</th>
                <th class="text-center py-3 px-4 font-medium text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="report in reports" 
                :key="report.id"
                class="border-t hover:bg-gray-50"
              >
                <td class="py-3 px-4">{{ report.service_id }}</td>
                <td class="py-3 px-4">{{ report.customer }}</td>
                <td class="py-3 px-4">{{ report.vehicle }}</td>
                <td class="py-3 px-4">{{ report.service_type.name }}</td>
                <td class="py-3 px-4">{{ formatDate(report.start_date) }}</td>
                <td class="py-3 px-4">
                  <span 
                    class="px-2 py-1 rounded-full text-sm font-medium"
                    :class="getStatusClass(report.status)"
                  >
                    {{ report.status.text }}
                  </span>
                </td>
                <td class="py-3 px-4">{{ report.advisor }}</td>
                <td class="py-3 px-4">
                  <div class="flex justify-center gap-2">
                    <!-- Quick View (Modal) -->
                    <button 
                      @click="viewDetails(report.id)"
                      class="inline-flex items-center text-blue-600 hover:text-blue-800 mt-1"
                      title="Quick View"
                    >
                      <EyeIcon class="h-5 w-5" />
                    </button>
                    
                    <!-- Detailed View (New Page) -->
                    <RouterLink 
                      :to="`/reports/${report.id}`"
                      class="inline-flex items-center text-gray-600 hover:text-gray-800"
                      title="Full Details"
                    >
                      <ArrowTopRightOnSquareIcon class="h-5 w-5" />
                    </RouterLink>

                    <!-- Optional: Dropdown Menu -->
                    <Menu as="div" class="relative inline-block text-left">
                      <MenuButton class="inline-flex items-center text-gray-400 hover:text-gray-600">
                        <EllipsisVerticalIcon class="h-5 w-5" />
                      </MenuButton>

                      <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                      >
                        <MenuItems 
                          class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <MenuItem v-slot="{ active }">
                            <button
                              @click="viewDetails(report.id)"
                              :class="[
                                active ? 'bg-gray-100' : '',
                                'flex w-full items-center px-4 py-2 text-sm text-gray-700'
                              ]"
                            >
                              <EyeIcon class="mr-3 h-5 w-5" />
                              Quick View
                            </button>
                          </MenuItem>

                          <MenuItem v-slot="{ active }">
                            <RouterLink
                              :to="`/reports/${report.id}`"
                              :class="[
                                active ? 'bg-gray-100' : '',
                                'flex w-full items-center px-4 py-2 text-sm text-gray-700'
                              ]"
                            >
                              <ArrowTopRightOnSquareIcon class="mr-3 h-5 w-5" />
                              Full Details
                            </RouterLink>
                          </MenuItem>
                        </MenuItems>
                      </transition>
                    </Menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination -->
        <div class="flex justify-between items-center">
          <div class="text-sm text-gray-500">
            Showing {{ startItem }} to {{ endItem }} of {{ pagination.total_items }} results
          </div>
          <div class="flex gap-4 items-center">
            <!-- Items per page -->
            <select 
              v-model="filters.limit" 
              class="border rounded-lg px-3 py-2"
            >
              <option :value="10">10 per page</option>
              <option :value="20">20 per page</option>
              <option :value="30">30 per page</option>
              <option :value="50">50 per page</option>
            </select>
            
            <!-- Page navigation -->
            <div class="flex gap-2">
              <button 
                @click="changePage(pagination.current_page - 1)"
                :disabled="!pagination.has_previous"
                class="px-4 py-2 border rounded-lg disabled:opacity-50"
              >
                Previous
              </button>
              <button 
                @click="changePage(pagination.current_page + 1)"
                :disabled="!pagination.has_next"
                class="px-4 py-2 border rounded-lg disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Detail Modal -->
    <TransitionRoot appear :show="showDetailModal" as="template">
      <Dialog as="div" @close="closeDetailModal" class="relative z-50">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 shadow-xl transition-all">
                <div v-if="selectedReport" class="space-y-4">
                  <!-- Header -->
                  <div class="flex justify-between items-start">
                    <div>
                      <h3 class="text-lg font-medium text-gray-900">
                        Service Details - {{ selectedReport.basic_info.service_id }}
                      </h3>
                      <p class="text-sm text-gray-500">
                        Created: {{ formatDate(selectedReport.basic_info.create_date) }}
                      </p>
                    </div>
                    <button 
                      @click="closeDetailModal"
                      class="text-gray-400 hover:text-gray-500"
                    >
                      <XMarkIcon class="h-6 w-6" />
                    </button>
                  </div>

                  <!-- Progress Bar -->
                  <div class="space-y-2">
                    <div class="flex justify-between text-sm">
                      <span class="font-medium">Progress</span>
                      <span>{{ selectedReport.basic_info.progress }}%</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-2">
                      <div 
                        class="bg-blue-600 h-2 rounded-full"
                        :style="`width: ${selectedReport.basic_info.progress}%`"
                      ></div>
                    </div>
                  </div>

                  <!-- Customer & Vehicle Info -->
                  <div class="grid grid-cols-2 gap-4">
                    <div class="space-y-2">
                      <h4 class="font-medium">Customer Information</h4>
                      <p>Name: {{ selectedReport.customer.name }}</p>
                      <p>Phone: {{ selectedReport.customer.phone }}</p>
                    </div>

                    <div class="space-y-2">
                      <h4 class="font-medium">Vehicle Information</h4>
                      <p>{{ selectedReport.vehicle.brand }} {{ selectedReport.vehicle.type }}</p>
                      <p>Plate: {{ selectedReport.vehicle.plate }}</p>
                      <p>Color: {{ selectedReport.vehicle.color }}</p>
                      <p>Year: {{ selectedReport.vehicle.year }}</p>
                    </div>
                  </div>

                  <!-- Service Metrics Summary -->
                  <div class="mb-6 grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div class="bg-white p-4 rounded-lg border">
                      <div class="text-sm text-gray-500">Total Duration</div>
                      <div class="text-xl font-bold">{{ selectedReport.timeline.metrics.total_duration_text }}</div>
                    </div>
                    
                    <div class="bg-white p-4 rounded-lg border">
                      <div class="text-sm text-gray-500">Active Service Time</div>
                      <div class="text-xl font-bold">{{ selectedReport.timeline.metrics.active_duration_text }}</div>
                    </div>
                    
                    <div class="bg-white p-4 rounded-lg border">
                      <div class="text-sm text-gray-500">Delay Duration</div>
                      <div class="text-xl font-bold" :class="{'text-red-600': selectedReport.timeline.metrics.has_delays}">
                        {{ selectedReport.timeline.metrics.delay_duration_text }}
                      </div>
                    </div>
                    
                    <div class="bg-white p-4 rounded-lg border">
                      <div class="text-sm text-gray-500">Status</div>
                      <div class="text-xl font-bold" :class="{'text-green-600': selectedReport.timeline.metrics.is_completed}">
                        {{ selectedReport.timeline.metrics.is_completed ? 'Completed' : 'In Progress' }}
                      </div>
                    </div>
                  </div>

                  <!-- Timeline -->
                  <div class="space-y-2">
                    <h4 class="font-medium">Service Timeline</h4>
                    <div class="relative">
                      <!-- Timeline events -->
                      <div class="space-y-4">
                        <div 
                          v-for="event in timelineEvents" 
                          :key="event.type"
                          class="flex items-start gap-3"
                        >
                          <div class="flex flex-col items-center">
                            <div 
                              class="w-3 h-3 rounded-full" 
                              :class="getEventStatusColor(event.status)"
                            ></div>
                            <div v-if="!isLastEvent(event)" class="w-px h-16 bg-gray-200"></div>
                          </div>
                          
                          <div class="flex-1">
                            <div class="flex justify-between items-start">
                              <div>
                                <p class="font-medium">{{ event.title }}</p>
                                <p class="text-sm text-gray-500">
                                  {{ formatDate(event.start_time) }}
                                  <template v-if="event.type === 'service_start' && event.end_time">
                                    <span class="mx-1">-</span>
                                    {{ formatDate(event.end_time) }}
                                  </template>
                                </p>
                              </div>
                              <div v-if="event.duration_text" 
                                   class="text-sm px-2 py-1 rounded-full"
                                   :class="getDurationClass(event)">
                                {{ event.duration_text }}
                                <span v-if="event.type === 'service_start'" class="ml-1 font-medium">
                                  (Service Time)
                                </span>
                              </div>
                            </div>
                            
                            <!-- Service Progress Indicator -->
                            <div v-if="event.type === 'service_start'"
                                 class="mt-2">
                              <div class="flex items-center justify-between text-sm text-gray-500">
                                <span>Service Progress</span>
                                <span>{{ getServiceProgress(event) }}%</span>
                              </div>
                              <div class="mt-1 w-full bg-gray-200 rounded-full h-2">
                                <div class="bg-blue-600 h-2 rounded-full transition-all duration-500"
                                     :style="{ width: `${getServiceProgress(event)}%` }">
                                </div>
                              </div>
                            </div>

                            <!-- Service Complete Indicator -->
                            <div v-if="event.type === 'service_complete' && event.status === 'completed'"
                                 class="mt-2 text-sm text-green-600 flex items-center gap-1">
                              <CheckCircleIcon class="h-4 w-4" />
                              <span>Service completed successfully</span>
                            </div>

                            <!-- Notes or Additional Info -->
                            <div v-if="event.notes" class="mt-2 text-sm text-gray-600">
                              {{ event.notes }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Service Staff -->
                  <div class="space-y-2">
                    <h4 class="font-medium">Service Staff</h4>
                    <div class="grid grid-cols-2 gap-4">
                      <div>
                        <p class="text-sm text-gray-600">Service Advisor:</p>
                        <p>{{ selectedReport.staff.advisors[0]?.name || '-' }}</p>
                      </div>
                      <div>
                        <p class="text-sm text-gray-600">Mechanic:</p>
                        <p>{{ selectedReport.staff.mechanics || '-' }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- Completion Info -->
                  <div v-if="selectedReport.completion" class="space-y-2">
                    <h4 class="font-medium">Completion Details</h4>
                    <div class="grid grid-cols-2 gap-4">
                      <div>
                        <p class="text-sm text-gray-600">Estimated:</p>
                        <p>{{ formatDate(selectedReport.completion.estimated) || 'Not set' }}</p>
                      </div>
                      <div>
                        <p class="text-sm text-gray-600">Actual:</p>
                        <p>{{ formatDate(selectedReport.completion.actual) || 'Not completed' }}</p>
                      </div>
                    </div>
                    <div v-if="selectedReport.completion.is_delayed" class="text-red-600 text-sm">
                      Service is delayed
                    </div>
                    <div v-if="selectedReport.completion.notes" class="text-sm">
                      Note: {{ selectedReport.completion.notes }}
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { 
  Dialog, 
  DialogPanel, 
  TransitionRoot, 
  TransitionChild 
} from '@headlessui/vue'
import { 
  MagnifyingGlassIcon,
  DocumentArrowDownIcon,
  XMarkIcon,
  EyeIcon,
  ArrowTopRightOnSquareIcon,
  EllipsisVerticalIcon,
  CheckCircleIcon
} from '@heroicons/vue/24/outline'
import { 
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { toast } from 'vue-sonner'
import apiClient from '../config/api'
import debounce from 'lodash/debounce'

// State
const loading = ref(false)
const searchQuery = ref('')
const showDetailModal = ref(false)
const selectedReport = ref(null)
const reports = ref([])

const filters = ref({
  dateRange: 'month',
  startDate: '',
  endDate: '',
  serviceType: 'all',
  status: 'all',
  page: 1,
  limit: 20
})

const stats = ref({
  total_services: null,
  avg_service_time: null,
  completion_rate: null,
  customer_satisfaction: null
})

const pagination = ref({
  total_items: 0,
  total_pages: 1,
  current_page: 1,
  items_per_page: 20,
  has_next: false,
  has_previous: false
})

// Computed
const startItem = computed(() => {
  const start = (pagination.value.current_page - 1) * filters.value.limit + 1
  return Math.min(start, pagination.value.total_items)
})

const endItem = computed(() => {
  const end = pagination.value.current_page * filters.value.limit
  return Math.min(end, pagination.value.total_items)
})

// Computed
const timelineEvents = computed(() => {
  if (!selectedReport.value?.timeline?.events) return []
  
  const events = [...selectedReport.value.timeline.events]
  
  // Find service start and complete events
  const startEvent = events.find(e => e.type === 'service_start')
  const completeEvent = events.find(e => e.type === 'service_complete')
  
  // If we have both events, calculate service duration
  if (startEvent && completeEvent) {
    const startTime = new Date(startEvent.start_time.replace(' WIB', ''))
    const endTime = new Date(completeEvent.start_time.replace(' WIB', ''))
    
    // Calculate duration in minutes
    const durationMinutes = (endTime - startTime) / (1000 * 60)
    
    // Update the events with duration info
    events.forEach(event => {
      if (event.type === 'service_start') {
        event.end_time = completeEvent.start_time
        event.duration_minutes = durationMinutes
        event.duration_text = formatDurationMinute(durationMinutes)
        event.status = 'completed'
      }
      if (event.type === 'service_complete') {
        event.duration_minutes = 0 // Complete event doesn't need duration
        event.status = 'completed'
      }
    })
  }
  
  // Sort events by start time
  return events.sort((a, b) => {
    return new Date(a.start_time.replace(' WIB', '')) - new Date(b.start_time.replace(' WIB', ''))
  })
})

// Helper function to format duration
const formatDurationMinute = (minutes) => {
  if (!minutes) return null
  
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = Math.round(minutes % 60)
  
  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m`
  }
  return `${remainingMinutes} menit`
}

const isLastEvent = (event) => {
  if (!timelineEvents.value.length) return true
  return event === timelineEvents.value[timelineEvents.value.length - 1]
}

const getServiceProgress = (event) => {
  const events = timelineEvents.value
  const startEvent = events.find(e => e.type === 'service_start')
  const completeEvent = events.find(e => e.type === 'service_complete')
  
  if (!startEvent || !completeEvent) return 0
  
  if (event.type === 'service_complete') return 100
  
  if (event.type === 'service_start') {
    // If service is completed, return 100
    if (event.status === 'completed') return 100
    
    // Calculate progress based on time elapsed
    const startTime = new Date(startEvent.start_time.replace(' WIB', ''))
    const currentTime = new Date()
    const totalDuration = event.duration_minutes || 0
    
    if (totalDuration > 0) {
      const elapsed = (currentTime - startTime) / (1000 * 60)
      return Math.min(Math.round((elapsed / totalDuration) * 100), 100)
    }
  }
  
  return 0
}

const getDurationClass = (event) => {
  if (!event.duration_text) return 'bg-gray-100 text-gray-600'
  
  // Special handling for service events
  if (event.type === 'service_start' || event.type === 'service_complete') {
    return event.status === 'completed' ? 'bg-green-100 text-green-600' : 'bg-blue-100 text-blue-600'
  }
  
  // For other events
  if (event.exceeded) {
    return 'bg-red-100 text-red-600'
  }
  
  return event.status === 'completed' ? 'bg-green-100 text-green-600' : 'bg-blue-100 text-blue-600'
}

const getEventStatusColor = (status) => {
  const colors = {
    completed: 'bg-green-500',
    in_progress: 'bg-blue-500',
    delayed: 'bg-red-500',
    default: 'bg-gray-500'
  }
  return colors[status] || colors.default
}

const formatDuration = (hours) => {
  if (!hours) return '0h'
  const h = Math.floor(hours)
  const m = Math.round((hours - h) * 60)
  
  if (h > 0) {
    return `${h}h ${m}m`
  }
  return `${m}m`
}

const getChangeClass = (change) => {
  if (!change) return 'text-gray-500'
  return change > 0 ? 'text-green-600' : 'text-red-600'
}

const formatChange = (change) => {
  if (!change) return 'No change'
  const prefix = change > 0 ? '↑' : '↓'
  return `${prefix} ${Math.abs(change).toFixed(1)}% from last period`
}

const formatPercentage = (value) => {
  if (!value && value !== 0) return '0%'
  return `${value.toFixed(1)}%`
}

const getTotalJobStops = () => {
  const jobStops = stats.value?.additional_metrics?.job_stops || {}
  return Object.values(jobStops).reduce((sum, count) => sum + count, 0)
}

const fetchReports = async () => {
  try {
    loading.value = true
    const params = {
      date_range: filters.value.dateRange,
      service_type: filters.value.serviceType,
      status: filters.value.status,
      search: searchQuery.value,
      page: filters.value.page,
      limit: filters.value.limit
    }

    // Add date range params if custom
    if (filters.value.dateRange === 'custom') {
      params.start_date = filters.value.startDate
      params.end_date = filters.value.endDate
    }

    const response = await apiClient.post('/web/service-report/table', {
      jsonrpc: "2.0",
      method: "call",
      params
    })

    if (response?.data?.result?.status === 'success') {
      const data = response.data.result.data
      reports.value = data.rows
      stats.value = data.stats
      pagination.value = data.pagination
    } else {
      throw new Error('Failed to fetch reports')
    }
  } catch (error) {
    console.error('Error fetching reports:', error)
    toast.error('Failed to load reports')
  } finally {
    loading.value = false
  }
}

const fetchReportDetails = async (id) => {
  try {
    loading.value = true
    const response = await apiClient.post('/web/service-report/detail', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        order_id: id
      }
    })

    if (response?.data?.result?.status === 'success') {
      selectedReport.value = response.data.result.data
    } else {
      throw new Error('Failed to fetch report details')
    }
  } catch (error) {
    console.error('Error fetching report details:', error)
    toast.error('Failed to load report details')
  } finally {
    loading.value = false
  }
}

const formatDate = (dateString) => {
  if (!dateString) return '-'
  const date = new Date(dateString)
  return new Intl.DateTimeFormat('id-ID', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date)
}

const getStatusClass = (status) => {
  const classes = {
    completed: 'bg-green-100 text-green-800',
    in_progress: 'bg-blue-100 text-blue-800',
    delayed: 'bg-yellow-100 text-yellow-800',
    belum_mulai: 'bg-gray-100 text-gray-800'
  }
  return classes[status.code] || classes.belum_mulai
}

const getEventStatusColor2 = (status) => {
  const colors = {
    completed: 'bg-green-500',
    in_progress: 'bg-blue-500',
    pending: 'bg-yellow-500',
    default: 'bg-gray-500'
  }
  return colors[status] || colors.default
}

const getStatsClass = (stat) => {
  if (!stat) return ''
  return stat.comparison === 'better' 
    ? 'text-green-600' 
    : 'text-red-600'
}

const formatStatsChange = (stat) => {
  if (!stat?.change) return 'No change'
  const prefix = stat.trend === 'up' ? '↑' : '↓'
  return `${prefix} ${Math.abs(stat.change)}% from last period`
}

const viewDetails = async (id) => {
  await fetchReportDetails(id)
  showDetailModal.value = true
}

const closeDetailModal = () => {
  showDetailModal.value = false
  selectedReport.value = null
}

const changePage = (page) => {
  filters.value.page = page
  fetchReports()
}

const exportReport = async () => {
  try {
    loading.value = true
    // Implement export functionality here
    toast.success('Report exported successfully')
  } catch (error) {
    console.error('Error exporting report:', error)
    toast.error('Failed to export report')
  } finally {
    loading.value = false
  }
}

// Watch for filter changes
watch([() => filters.value.dateRange, () => filters.value.serviceType, 
       () => filters.value.status, () => filters.value.limit, searchQuery], 
  debounce(() => {
    filters.value.page = 1 // Reset to first page
    fetchReports()
  }, 300)
)

// Watch for custom date range changes
watch([() => filters.value.startDate, () => filters.value.endDate], () => {
  if (filters.value.dateRange === 'custom' && 
      filters.value.startDate && 
      filters.value.endDate) {
    filters.value.page = 1
    fetchReports()
  }
})

// Initialize
onMounted(() => {
  fetchReports()
})
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>