import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '../config/api'
import { useRouter } from 'vue-router'

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null)
  const isAuthenticated = ref(false)
  const router = useRouter()
  const isLoggingOut = ref(false)

  // Odoo specific session handling
  apiClient.interceptors.response.use(
    (response) => {
      if (response.data?.error) {
        const error = response.data.error;
        
        // Check Odoo session expiration indicators
        if (
          error.code === 100 || // Session invalid
          error.message?.includes('Session expired') ||
          error.data?.name === 'odoo.http.SessionExpiredException' ||
          error.data?.message?.includes('Session expired') ||
          error.data?.debug?.includes('SessionExpiredException')
        ) {
          // Cek jika tidak sedang logout
          if (!isLoggingOut.value) {
            handleSessionExpired()
          }
          return Promise.reject(error)
        }
      }
      return response
    },
    (error) => {
      if (
        !isLoggingOut.value && // Tambah pengecekan
        (error.response?.status === 401 || 
         error.response?.status === 403 ||
         error.response?.status === 419 ||
         error.response?.status === 404)
      ) {
        handleSessionExpired()
      }
      return Promise.reject(error)
    }
  )

  const handleSessionExpired = () => {
    // Cek jika sedang dalam proses logout
    if (isLoggingOut.value) return

    // Trigger session expired event
    window.dispatchEvent(new Event('session-expired'))
    
    // Small delay to ensure toast is shown before redirect
    setTimeout(() => {
      handleLogout(true) // Pass parameter untuk menandai ini dari session expired
    }, 100)
  }

  const handleLogout = async (isExpired = false) => {
    // Cek jika sedang dalam proses logout
    if (isLoggingOut.value) return
    
    try {
      isLoggingOut.value = true // Set flag
      
      // Hanya panggil destroy jika bukan dari session expired
      if (!isExpired) {
        try {
          await apiClient.post('/web/session/destroy', {})
        } catch (error) {
          console.error('Logout API error:', error)
          // Tangkap error tapi tetap lanjutkan logout
        }
      }
    } finally {
      // Cleanup
      user.value = null
      isAuthenticated.value = false
      localStorage.clear()
      isLoggingOut.value = false // Reset flag
      router.push('/login')
    }
  }

  // Odoo session check
  const startSessionCheck = () => {
    const checkSession = async () => {
      try {
        const response = await apiClient.post('/web/session/check', {
          jsonrpc: '2.0',
          id: new Date().getTime()
        })

        if (response.data.error || !response.data.result) {
          handleSessionExpired()
        }
      } catch (error) {
        console.error('Session check failed:', error)
        if (error.response?.status === 401 || error.response?.status === 403) {
          handleSessionExpired()
        }
      }
    }

    const intervalId = setInterval(checkSession, 5 * 60 * 1000) // Check setiap 5 menit
    checkSession() // Initial check
    return () => clearInterval(intervalId)
  }

  const login = async (database, email, password) => {
    try {
      const payload = {
        jsonrpc: '2.0',
        id: new Date().getTime(),
        params: {
          db: database,
          login: email,
          password: password
        }
      }

      const response = await apiClient.post('/web/session/authenticate', payload)

      const sessionId = response.headers['x-openerp-session-id']
      if (sessionId) {
        localStorage.setItem('sessionId', sessionId)
      }

      if (response.data.error) {
        throw new Error(response.data.error.data?.message || 'Authentication failed')
      }

      const userData = response.data.result
      user.value = userData
      isAuthenticated.value = true

      // Store essential data in localStorage
      localStorage.setItem('uid', userData.uid)
      localStorage.setItem('user_context', JSON.stringify(userData.user_context))
      localStorage.setItem('username', userData.username)
      localStorage.setItem('name', userData.name)
      localStorage.setItem('company_id', userData.company_id)
      localStorage.setItem('partner_id', userData.partner_id)
      localStorage.setItem('database', database)
      localStorage.setItem('isAdmin', userData.is_admin)

      return userData
    } catch (error) {
      console.error('❌ Login failed:', error)
      throw error
    }
  }

  const checkAuth = () => {
    const uid = localStorage.getItem('uid')
    const username = localStorage.getItem('username')
    
    if (uid && username) {
      isAuthenticated.value = true
      user.value = {
        uid,
        username,
        name: localStorage.getItem('name'),
        company_id: localStorage.getItem('company_id'),
        partner_id: localStorage.getItem('partner_id'),
        is_admin: localStorage.getItem('isAdmin') === 'true'
      }
    } else {
      handleLogout()
    }
  }

  return {
    user,
    isAuthenticated,
    login,
    logout: handleLogout,
    checkAuth,
    startSessionCheck
  }
})