// constants/jobStatus.js
export const JOB_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  PAUSED: 'paused',
  COMPLETED: 'completed'
}

// Job stop types harus sesuai dengan API response
export const JOB_STOP_TYPES = {
  TUNGGU_KONFIRMASI: 'tunggu_konfirmasi',
  TUNGGU_PART: 'tunggu_part_1',         // Sesuaikan dengan API
  TUNGGU_PART_2: 'tunggu_part_2',     // Untuk part 2
  ISTIRAHAT: 'istirahat',
  TUNGGU_SUBLET: 'tunggu_sublet',
  JOB_STOP_LAIN: 'job_stop_lain'
}

export const STATUS_TEXT_MAP = {
  'not_started': 'Belum Dimulai',
  'in_progress': 'Sedang Dikerjakan',
  'paused': 'Dijeda',
  'completed': 'Selesai',
  'proses': 'Sedang Dikerjakan',
  'tunggu_part': 'Menunggu Part',
  'tunggu_part_2': 'Menunggu Part 2',    // Tambahkan text untuk part 2
  'tunggu_konfirmasi': 'Menunggu Konfirmasi',
  'tunggu_sublet': 'Menunggu Sublet',    // Tambahkan text untuk sublet
  'istirahat': 'Istirahat',
  'job_stop_lain': 'Dijeda Job Stop Lain'
}

export const JOB_STOP_OPTIONS = [
  { 
    value: JOB_STOP_TYPES.TUNGGU_KONFIRMASI,
    label: 'Tunggu Konfirmasi',
    description: 'Menunggu konfirmasi dari customer untuk penggantian atau perbaikan tambahan',
    requiresPriorStop: false
  },
  { 
    value: JOB_STOP_TYPES.TUNGGU_PART,     // Sesuaikan dengan API
    label: 'Tunggu Part 1',
    description: 'Menunggu ketersediaan spare part untuk perbaikan pertama',
    requiresPriorStop: false
  },
  { 
    value: JOB_STOP_TYPES.TUNGGU_PART_2,
    label: 'Tunggu Part 2',
    description: 'Menunggu ketersediaan spare part untuk perbaikan tambahan setelah part 1',
    requiresPriorStop: true,
    requiredPriorStop: JOB_STOP_TYPES.TUNGGU_PART  // Sesuaikan dengan API
  },
  { 
    value: JOB_STOP_TYPES.TUNGGU_SUBLET,
    label: 'Tunggu Sublet',
    description: 'Menunggu pengerjaan dari pihak subkontraktor atau vendor eksternal',
    requiresPriorStop: false
  },
  { 
    value: JOB_STOP_TYPES.ISTIRAHAT,
    label: 'Istirahat',
    description: 'Waktu istirahat mekanik sesuai jadwal',
    requiresPriorStop: false
  },
  { 
    value: JOB_STOP_TYPES.JOB_STOP_LAIN,
    label: 'Job Stop Lain',
    description: 'Penghentian sementara karena alasan lain yang perlu dicatat',
    requiresPriorStop: false
  }
]

export const STATUS_CLASS_MAP = {
  [JOB_STATUS.NOT_STARTED]: 'text-gray-500',
  [JOB_STATUS.IN_PROGRESS]: 'text-green-500',
  [JOB_STATUS.PAUSED]: 'text-yellow-500',
  [JOB_STATUS.COMPLETED]: 'text-blue-500'
}

// Kemudian update isJobStopAvailable
export const isJobStopAvailable = (jobStops, stopType) => {
  if (!jobStops) return { available: false, reason: 'No job stops data' };
  
  // Handle kasus khusus untuk tunggu_part
  if (stopType === JOB_STOP_TYPES.TUNGGU_PART) {
    const part1Stop = jobStops['tunggu_part'];
    if (part1Stop?.completed) {
      return { 
        available: false, 
        reason: 'Tunggu Part 1 sudah selesai' 
      };
    }
    if (part1Stop?.active) {
      return { 
        available: false, 
        reason: 'Tunggu Part 1 sedang aktif' 
      };
    }
  }

  // Regular checks
  const stop = jobStops[stopType];
  const stopOption = JOB_STOP_OPTIONS.find(opt => opt.value === stopType);
  
  // Check for any active stops
  const hasActiveStop = Object.values(jobStops).some(s => s.active);
  if (hasActiveStop) {
    return { 
      available: false, 
      reason: 'Sedang ada job stop yang aktif' 
    };
  }
  
  return { available: true };
}

export const formatJobStopType = (type) => {
  if (!type) return '';
  const option = JOB_STOP_OPTIONS.find(opt => opt.value === type);
  return option ? option.label : '';
}

export const getJobStatus = (item) => {
  if (!item) return JOB_STATUS.NOT_STARTED;
  
  if (item.timestamps?.selesai_servis) {
    return JOB_STATUS.COMPLETED;
  }
  
  // Check all possible active stops
  if (item.job_stops) {
    const hasActiveStop = Object.entries(item.job_stops).some(([type, stop]) => 
      stop.active && [
        'tunggu_part',
        'tunggu_part_2',
        'tunggu_konfirmasi',
        'tunggu_sublet',
        'istirahat',
        'job_stop_lain'
      ].includes(type)
    );
    
    if (hasActiveStop) return JOB_STATUS.PAUSED;
  }
  
  if (item.status?.code === 'in_progress' || item.status?.code === 'proses') {
    return JOB_STATUS.IN_PROGRESS;
  }
  
  return JOB_STATUS.NOT_STARTED;
}