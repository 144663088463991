<!-- components/PWAUpdatePopup.vue -->
<template>
  <div v-if="needRefresh" class="pwa-refresh-popup">
    <div class="popup-content">
      <p>New content available, click on reload button to update.</p>
      <button @click="updateServiceWorker()" class="refresh-button">
        Reload
      </button>
      <button @click="close" class="close-button">
        Close
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const needRefresh = ref(false)
const updateServiceWorker = ref(() => {})

if (process.env.NODE_ENV === 'production') {
  import('virtual:pwa-register').then(({ registerSW }) => {
    updateServiceWorker.value = registerSW({
      immediate: true,
      onNeedRefresh() {
        needRefresh.value = true
      },
      onOfflineReady() {
        console.log('App ready to work offline')
      }
    })
  })
}

const close = () => {
  needRefresh.value = false
}
</script>

<style scoped>
.pwa-refresh-popup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.refresh-button {
  padding: 8px 16px;
  background: #4F46E5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button {
  padding: 8px 16px;
  background: #E5E7EB;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}
</style>