<template>
  <Toaster 
    position="top-right"
    :duration="3000"
    :richColors="true"
  />
  <div class="p-6">
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <!-- Loading Overlay -->
      <div v-if="loading" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white p-6 rounded-lg shadow-lg text-center">
          <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p class="mt-4 text-gray-600">Memuat data...</p>
        </div>
      </div>

      <!-- Content Area -->
      <div class="space-y-6">

        

        <!-- Summary Section -->
        <!-- <div v-if="summary" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div class="bg-gray-50 p-4 rounded-lg">
            <div class="text-sm text-gray-600">Total Service</div>
            <div class="text-2xl font-semibold">{{ formatNumber(summary.total) }}</div>
          </div>
          <div class="bg-gray-50 p-4 rounded-lg">
            <div class="text-sm text-gray-600">Proses</div>
            <div class="text-2xl font-semibold">{{ formatNumber(summary.proses) }}</div>
          </div>
          <div class="bg-gray-50 p-4 rounded-lg">
            <div class="text-sm text-gray-600">Mekanik</div>
            <div class="text-2xl font-semibold">
              {{ summary.mechanics?.on_duty || 0 }}/{{ summary.mechanics?.total || 0 }}
            </div>
          </div>
          <div class="bg-gray-50 p-4 rounded-lg">
            <div class="text-sm text-gray-600">Service Advisor</div>
            <div class="text-2xl font-semibold">
              {{ summary.service_advisors?.on_duty || 0 }}/{{ summary.service_advisors?.total || 0 }}
            </div>
          </div>
        </div> -->

        <!-- Header -->
        <div class="flex flex-col md:flex-row justify-between items-center">
          <div class="flex items-center space-x-4 mb-4 md:mb-0">
            <h1 class="text-xl font-semibold">Leads Time</h1>
          </div>
          <div class="text-gray-600 font-medium">
            {{ formatDate(currentDate) }}
          </div>
        </div>

        <!-- Search, Clock, and Filter -->
        <div class="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div class="relative w-full md:w-auto">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Cari nomor plat atau jenis mobil"
              class="w-full md:w-80 pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              @input="handleSearch"
            >
            <div class="absolute left-3 top-2.5">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" />
            </div>
          </div>
          
          <!-- Jam Berjalan -->
          <div class="text-2xl font-bold">
            {{ currentTime }}
          </div>

          <div class="flex flex-wrap gap-2">
            <button 
              v-for="filter in filters" 
              :key="filter.value"
              :class="[
                'px-4 py-2 rounded-lg transition-colors duration-200',
                currentFilter === filter.value 
                  ? 'bg-gray-900 text-white hover:bg-gray-800' 
                  : 'bg-white border hover:bg-gray-50'
              ]"
              @click="handleFilterChange(filter.value)"
            >
              {{ filter.label }}
            </button>
          </div>
        </div>

        <!-- Table Section -->
        <div v-if="rows.length > 0" class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
                <tr>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 5%;">No</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 10%;">Jenis Mobil</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 10%;">Plat Mobil</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 12%;">Estimasi Selesai</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 3%;">Job</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 15%;">Status</th>
                  <!-- <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 20%;">Job & Status</th> -->
                  <!-- <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 10%;">Progress</th> -->
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 15%;">Detail Servis</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 8%;">Mekanik</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 10%;">Service Advisor</th>
                  <th class="text-left py-3 px-4 font-medium text-gray-700" style="width: 8%;">Catatan</th>
                </tr>
              </thead>
            <tbody>
              <tr 
                v-for="item in rows" 
                :key="item.id" 
                class="border-t hover:bg-gray-50 transition-colors duration-150"
              >
                <td class="py-3 px-4 font-medium">#{{ formatNumber(item.no) }}</td>
                <td class="py-3 px-4">{{ item.jenis_mobil }}</td>
                <td class="py-3 px-4">{{ item.plat_mobil?.toUpperCase() || '-' }}</td>
                <td class="py-3 px-4 cursor-pointer hover:bg-gray-100" @click="openEstimationDialog(item)">
                  {{ formatEstimatedTime(item.estimasi_selesai) }}
                </td>
                <td class="py-3 px-4">
                  <div class="flex items-center space-x-3">
                    <!-- Job Toggle Switch -->
                    <div class="flex-shrink-0">
                      <Switch 
                        :model-value="isJobActive(item)"
                        @update:model-value="(value) => handleJobStatusChange(value, item)"
                        :disabled="!canToggleJob(item)"
                        class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        :class="[
                          isJobActive(item) ? 'bg-green-500' : 'bg-gray-200',
                          'disabled:opacity-50 disabled:cursor-not-allowed'
                        ]"
                      >
                        <span class="sr-only">Toggle job status</span>
                        <span
                          class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
                          :class="isJobActive(item) ? 'translate-x-6' : 'translate-x-1'"
                        />
                      </Switch>
                    </div>
                  </div>
                </td>
                <td class="py-3 px-4">
                  <!-- Status Badge and Info -->
                  <div class="flex flex-col gap-1">
                    <span 
                      class="px-3 py-1 rounded-full text-sm font-medium inline-flex items-center gap-2"
                      :class="{
                        'bg-green-100 text-green-800': item.status.code === 'completed',
                        'bg-yellow-100 text-yellow-800': item.status.code === 'tunggu_part',
                        'bg-red-100 text-red-800': item.status.code === 'tunggu_konfirmasi',
                        'bg-purple-100 text-purple-800': item.status.code === 'tunggu_sublet',
                        'bg-blue-100 text-blue-800': item.status.code === 'in_progress',
                        'bg-gray-100 text-gray-800': item.status.code === 'not_started',
                        'bg-orange-100 text-orange-800': item.status.code === 'istirahat',
                        'bg-indigo-100 text-indigo-800': item.status.code === 'job_stop_lain'
                      }"
                    >
                      {{ item.status.text }}
                      
                    </span>
                    <span v-if="getActiveJobStopTime(item)" class="text-sm mt-2 ml-2 text-gray-700">
                      {{ getActiveJobStopTime(item) }}
                    </span>
                    <span v-if="item.status.code === 'in_progress'" class="text-sm mt-2 ml-2 text-gray-700">
                      Mulai: {{ formatEstimatedTime(item.timestamps.mulai_servis) }} ({{ formatDuration(item.timestamps.mulai_servis) }})
                    </span>
                    <span v-if="item.status.code === 'completed'" class="text-sm mt-2 ml-2 text-gray-700">
                      Selesai: {{ formatEstimatedTime(item.timestamps.selesai_servis) }} ({{ TotalDuration(item.timestamps) }})
                    </span>
                  </div>
                </td>
                 <!-- Kolom Job yang dimodifikasi -->
                 <!-- <td class="py-3 px-4">
                  <div class="flex items-center gap-2">
                    <Switch 
                        :model-value="isJobActive(item)"
                        @update:model-value="(value) => handleJobStatusChange(value, item)"
                        :disabled="!canToggleJob(item)"
                        class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        :class="[
                          isJobActive(item) ? 'bg-green-500' : 'bg-gray-200',
                          'disabled:opacity-50 disabled:cursor-not-allowed'
                        ]"
                      >
                      <span class="sr-only">Toggle job status</span>
                      <span
                        class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
                        :class="isJobActive(item) ? 'translate-x-6' : 'translate-x-1'"
                      />
                    </Switch>
                    <span 
                      :class="[
                        'text-sm font-medium',
                        getStatusClass(item)
                      ]"
                    >
                      {{ getStatusText(item) }}
                    </span>
                    
                    <span v-if="getActiveJobStopTime(item)" 
                        class="text-xs text-gray-500 mt-1">
                        {{ getActiveJobStopTime(item) }}
                    </span>
                  </div>
                </td>
                <td class="py-3 px-4">
                  <span 
                    class="px-3 py-1 rounded-full text-sm font-medium"
                    :class="{
                      'bg-green-100 text-green-800': item.status.code === 'completed',
                      'bg-yellow-100 text-yellow-800': item.status.code === 'tunggu_part',
                      'bg-red-100 text-red-800': item.status.code === 'tunggu_konfirmasi',
                      'bg-purple-100 text-purple-800': item.status.code === 'tunggu_sublet',
                      'bg-blue-100 text-blue-800': item.status.code === 'in_progress',
                      'bg-gray-100 text-gray-800': item.status.code === 'not_started',
                      'bg-orange-100 text-orange-800': item.status.code === 'istirahat',
                      'bg-indigo-100 text-indigo-800': item.status.code === 'job_stop_lain'
                    }"
                  >
                    {{ item.status.text }}
                  </span>
                </td> -->
                <!-- <td class="py-3 px-4">
                  <div class="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    class="bg-blue-600 h-2.5 rounded-full" 
                    :style="{ width: `${Math.round(item.progress.percentage)}%` }"
                  ></div>
                  </div>
                  <div class="text-sm text-gray-600 mt-1">
                  {{ Math.round(item.progress.percentage) }}%
                  </div>
                </td> -->
                <!-- Kolom Jasa Servis yang ditingkatkan -->
                <td class="py-3 px-4">
                  <div v-if="item.service?.details?.items?.length > 0" class="relative group">
                    <div class="space-y-1">
                      <!-- Tampilkan 2 item pertama dengan nomor -->
                      <div 
                        v-for="(service, index) in item.service.details.items.slice(0, 2)" 
                        :key="service.id" 
                        class="text-sm text-gray-700"
                      >
                        {{ index + 1 }}. {{ service.name }}
                      </div>
                      
                      <!-- Jika ada lebih dari 2 item, tampilkan indikator -->
                      <div 
                        v-if="item.service.details.items.length > 2" 
                        class="text-sm text-blue-600 cursor-pointer hover:text-blue-800"
                      >
                        +{{ item.service.details.items.length - 2 }} servis lainnya
                      </div>
                    </div>
                
                    <!-- Tooltip untuk menampilkan semua servis saat hover -->
                    <div 
                      v-if="item.service.details.items.length > 2"
                      class="hidden group-hover:block absolute z-10 bg-white border rounded-lg shadow-lg p-3 min-w-[200px] left-0 mt-1"
                    >
                      <div class="space-y-1 max-h-[200px] overflow-y-auto">
                        <div 
                          v-for="(service, index) in item.service.details.items" 
                          :key="service.id" 
                          class="text-sm text-gray-700"
                        >
                          {{ index + 1 }}. {{ service.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="text-sm text-gray-500">-</div>
                </td>
                
                <td class="py-3 px-4">{{ item.mekanik || '-' }}</td>
                <td class="py-3 px-4">{{ item.service_advisor || '-' }}</td>
                 <!-- Update kolom catatan dan estimasi di table -->
                <td class="py-3 px-4 cursor-pointer hover:bg-gray-100" @click="openNotesDialog(item)">
                  {{ item.catatan || '-' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Empty State -->
        <div 
          v-else-if="!loading" 
          class="text-center py-12 bg-gray-50 rounded-lg"
        >
          <h3 class="text-lg font-medium text-gray-900 mb-2">Tidak ada data</h3>
          <p class="text-gray-500">
            {{ searchQuery ? 'Tidak ada hasil yang cocok dengan pencarian Anda' : 'Belum ada data yang tersedia' }}
          </p>
        </div>

        <!-- Pagination -->
        <div 
          v-if="pagination && rows.length > 0" 
          class="flex flex-col md:flex-row justify-between items-center"
        >
          <div class="text-sm text-gray-600 mb-4 md:mb-0">
            Menampilkan {{ pagination.start_number }} sampai {{ pagination.end_number }} 
            dari {{ pagination.total_items }} data
          </div>
          <div class="flex space-x-2">
            <button 
              class="px-4 py-2 border rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors duration-200"
              :disabled="!pagination.has_previous"
              @click="handlePageChange(pagination.current_page - 1)"
            >
              Sebelumnya
            </button>
            <button 
              class="px-4 py-2 border rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 transition-colors duration-200"
              :disabled="!pagination.has_next"
              @click="handlePageChange(pagination.current_page + 1)"
            >
              Selanjutnya
            </button>
          </div>
        </div>
        

      </div>
    </div>
    <!-- Dialog untuk Job Control -->
    <!-- Dialog (Diperbarui) -->
    <TransitionRoot appear :show="showActionDialog" as="template">
      <Dialog as="div" class="relative z-50" @close="handleModalClose">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 flex items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
          <DialogPanel :class="[
              'transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative',
              {
                'w-full max-w-md': selectedRow?.jobStatus === JOB_STATUS.NOT_STARTED, // Dialog kecil untuk mulai servis
                'w-full min-w-[900px] max-w-5xl': selectedRow?.jobStatus === JOB_STATUS.IN_PROGRESS, // Dialog besar untuk job stop
                'w-full max-w-2xl': selectedRow?.jobStatus === JOB_STATUS.PAUSED // Dialog sedang untuk resume
              }
            ]">
            
          <div v-if="selectedRow">
              <!-- Start Service Dialog - tetap sama -->
              <template v-if="selectedRow.jobStatus === JOB_STATUS.NOT_STARTED">
                <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Mulai Pekerjaan
                </h3>
                <div class="space-y-4">
                  <button 
                    @click="startNewJob"
                    class="w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                  >
                    Mulai Servis Baru
                  </button>
                </div>
              </template>
    
              <!-- Pause Service Dialog - diperbarui -->
              <template v-else-if="selectedRow.jobStatus === JOB_STATUS.IN_PROGRESS">
                <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Pilih Tindakan
                </h3>
                
                <div class="flex gap-6">
                  <!-- Left Column - Available Job Stops -->
                  <div class="w-2/3 space-y-4">
                    <div class="grid grid-cols-2 gap-4">
                      <button
                        v-for="option in availableJobStops"
                        :key="option.value"
                        @click="option.disabled ? null : selectJobStop(option)"
                        :class="[
                          'p-4 rounded-lg border text-left transition-colors h-full',
                          {
                            'border-blue-500 bg-blue-50': selectedJobStop === option.value,
                            'border-gray-200 hover:border-blue-200': !option.disabled && selectedJobStop !== option.value,
                            'opacity-50 cursor-not-allowed bg-gray-50': option.disabled,
                            'cursor-pointer': !option.disabled
                          }
                        ]"
                      >
                        <div class="h-full flex flex-col">
                          <div class="font-medium">{{ option.label }}</div>
                          <div class="text-xs text-gray-500 mt-1 flex-grow">{{ option.description }}</div>
                          <div v-if="option.disabled" class="text-xs text-red-500 mt-2">
                            {{ getDisabledReason(option) }}
                          </div>
                        </div>
                      </button>
            
                      <!-- Complete Service Button -->
                      <button
                        @click="selectJobStop({ value: 'complete', label: 'Selesai Servis' })"
                        :class="[
                          'p-4 rounded-lg border text-left transition-colors h-full',
                          selectedJobStop === 'complete'
                            ? 'border-green-500 bg-green-50' 
                            : 'border-gray-200 hover:border-green-200'
                        ]"
                      >
                        <div class="h-full flex flex-col">
                          <div class="font-medium text-green-700">Selesai Servis</div>
                          <div class="text-xs text-gray-500 mt-1">Selesaikan pekerjaan servis</div>
                        </div>
                      </button>
                    </div>
            
                    <!-- Form untuk Job Stop Lain -->
                    <div v-if="selectedJobStop === JOB_STOP_TYPES.JOB_STOP_LAIN" class="mt-4">
                      <textarea
                        v-model="jobStopNote"
                        placeholder="Jelaskan alasan job stop..."
                        class="w-full p-2 border rounded-lg"
                        rows="3"
                      />
                    </div>
                  </div>
            
                  <!-- Right Column - Job Stop Times -->
                  <div class="w-1/3 bg-gray-50 rounded-lg p-4">
                    <div class="text-sm font-medium text-gray-700 mb-3">Status Job Stop</div>
                    
                    <!-- Current Active Job Stop if any -->
                    <div v-if="getCurrentActiveJobStop" class="mb-4 p-3 bg-white rounded-lg border border-yellow-200">
                      <div class="text-sm font-medium text-yellow-600">Sedang Aktif</div>
                      <div class="mt-1">
                        <div class="font-medium">{{ formatJobStopType(getCurrentActiveJobStop.type) }}</div>
                        <div class="text-sm text-gray-500">
                          Mulai: {{ formatTimeOnly(getCurrentActiveJobStop.start) }}
                        </div>
                        <div class="text-sm font-medium text-gray-600 mt-1">
                          Durasi: {{ formatDuration(getCurrentActiveJobStop.start) }}
                        </div>
                      </div>
                    </div>
            
                    <!-- Job Stop History -->
                    <div class="space-y-2">
                      <div v-for="history in getCompletedJobStops" 
                           :key="history.type"
                           class="p-3 bg-white rounded-lg border"
                      >
                        <div class="font-medium">{{ formatJobStopType(history.type) }}</div>
                        <div class="text-sm text-gray-500">
                          {{ formatTimeRange(history.start, history.end) }}
                        </div>
                        <div class="text-sm font-medium text-gray-600 mt-1">
                          Durasi: {{ formatDuration(history.start, history.end) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                <!-- Action Buttons -->
                <div class="mt-6 flex gap-3">
                  <!-- Button Batal - Posisi kiri -->
                  <button 
                    @click="handleModalClose"
                    class="px-4 py-2 flex-1 bg-gray-100 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-200 transition-colors"
                  >
                    Batal
                  </button>

                  <!-- Button Konfirmasi - Posisi kanan -->
                  <button 
                    v-if="selectedJobStop === 'complete'"
                    @click="completeService"
                    class="px-4 py-2 flex-1 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors font-medium"
                  >
                    Konfirmasi Selesai
                  </button>
                  <button 
                    v-else
                    @click="pauseJob"
                    class="px-4 py-2 flex-1 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors font-medium"
                    :disabled="!selectedJobStop"
                    :class="{'opacity-50 cursor-not-allowed': !selectedJobStop}"
                  >
                    {{ selectedJobStop ? 'Konfirmasi Job Stop' : 'Pilih Job Stop' }}
                  </button>
                </div>
              </template>
    
              <!-- Resume Service Dialog - tetap sama dengan perbaikan UI -->
              <!-- Update dialog untuk menampilkan history job stop -->
              <template v-else-if="selectedRow.jobStatus === JOB_STATUS.PAUSED">
                <h3 class="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Lanjutkan Pekerjaan
                </h3>
                <div class="space-y-4">
                  <div class="bg-blue-50 p-4 rounded-lg">
                    <p class="text-sm text-blue-800 font-medium mb-1">
                      Status: {{ formatJobStopType(selectedRow.currentJobStopType) }}
                    </p>
                    <p class="text-xs text-blue-600">
                      {{ getJobStopDuration(selectedRow) }}
                    </p>
                  </div>
                  
                  <!-- Job Stop History -->
                  <div v-if="getJobStopHistory(selectedRow).length > 0" class="mt-4">
                    <h4 class="text-sm font-medium text-gray-700 mb-2">Riwayat Job Stop:</h4>
                    <div class="space-y-2">
                      <div v-for="(history, index) in getJobStopHistory(selectedRow)" 
                          :key="index"
                          class="text-xs bg-gray-50 p-2 rounded">
                        <div class="font-medium text-gray-700">
                          {{ formatJobStopType(history.type) }}
                        </div>
                        <div class="text-gray-500">
                          {{ formatJobStopHistoryTime(history) }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Action Buttons -->
                <div class="mt-6 flex gap-3">
                  <!-- Button Batal - Posisi kiri -->
                  <button 
                    @click="handleModalClose"
                    class="px-4 py-2 flex-1 bg-gray-100 text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-200 transition-colors"
                  >
                    Batal
                  </button>

                  <!-- Button Konfirmasi - Posisi kanan -->
                  <button 
                    @click="resumeJob"
                    class="w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                  >
                    Lanjutkan Servis
                  </button>
                </div>

                  
                </div>
              </template>
            </div>
          </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
      <!-- Tambahkan di bagian atas template, di luar table -->
      <TransitionRoot appear :show="showNotesDialog" as="template">
        <Dialog as="div" @close="closeNotesDialog" class="relative z-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
          </TransitionChild>
    
          <div class="fixed inset-0 flex items-center justify-center p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div class="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Edit Catatan
                </div>
                <div class="mt-2">
                  <textarea
                    v-model="editedNotes"
                    rows="4"
                    class="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Masukkan catatan..."
                  ></textarea>
                </div>
                <div class="mt-4 flex justify-end space-x-3">
                  <button
                    @click="closeNotesDialog"
                    class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                  >
                    Batal
                  </button>
                  <button
                    @click="saveNotes"
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    Simpan
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    
      <TransitionRoot appear :show="showEstimationDialog" as="template">
        <Dialog as="div" @close="closeEstimationDialog" class="relative z-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
          </TransitionChild>
    
          <div class="fixed inset-0 flex items-center justify-center p-4">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div class="text-lg font-medium leading-6 text-gray-900 mb-4">
                  Edit Estimasi Waktu
                </div>
                <div class="mt-2 space-y-4">
                  <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      Estimasi Mulai
                    </label>
                    <input
                      type="time"
                      v-model="editedEstimation.mulai"
                      class="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label class="block text-sm font-medium text-gray-700 mb-1">
                      Estimasi Selesai
                    </label>
                    <input
                      type="time"
                      v-model="editedEstimation.selesai"
                      class="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                <div class="mt-4 flex justify-end space-x-3">
                  <button
                    @click="closeEstimationDialog"
                    class="px-4 py-2 border rounded-lg hover:bg-gray-50"
                  >
                    Batal
                  </button>
                  <button
                    @click="saveEstimation"
                    class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    Simpan
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  </div>
</template>

<!-- // File: src/views/LeadsTime.vue -->
<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { Toaster, toast } from 'vue-sonner'
import { 
  Dialog, 
  DialogPanel, 
  TransitionRoot, 
  TransitionChild,
  Switch
} from '@headlessui/vue'
import { 
  JOB_STATUS, 
  JOB_STOP_TYPES, 
  JOB_STOP_OPTIONS, 
  STATUS_TEXT_MAP,
  STATUS_CLASS_MAP,
  formatJobStopType,
  isJobStopAvailable  
} from '../constants/jobStatus'
import apiClient from '../config/api'
import debounce from 'lodash/debounce'

// Existing state
const loading = ref(false)
const error = ref(null)
const rows = ref([])
const summary = ref(null)
const pagination = ref(null)
const currentDate = ref('')
const searchQuery = ref('')
const currentFilter = ref('all')
const currentTime = ref('')
let clockInterval = null
let refreshInterval = null
// State global untuk refresh trigger
const refreshTrigger = ref(0)
// State untuk dialog
const showNotesDialog = ref(false)
const showEstimationDialog = ref(false)
const selectedItem = ref(null)
const editedNotes = ref('')
const editedEstimation = ref({
  mulai: '',
  selesai: ''
})

// Methods untuk notes dialog
const openNotesDialog = (item) => {
  selectedItem.value = item
  editedNotes.value = item.catatan || ''
  showNotesDialog.value = true
}

const showAllServices = (item) => {
  // Optional: Implementasi modal atau dropdown untuk menampilkan semua servis
  console.log('Show all services for item:', item.id);
};

// Update fungsi save untuk menggunakan pattern yang konsisten
const saveNotes = async () => {
  if (!selectedItem.value) return

  try {
    loading.value = true
    const response = await apiClient.put(`/web/lead-time/${selectedItem.value.id}/notes`, {
      jsonrpc: "2.0",
      method: "call",
      params: {
        notes: editedNotes.value
      }
    })

    if (response?.data?.result?.status === 'success') {
      refreshTrigger.value++ // Trigger refresh
      showNotesDialog.value = false
      selectedItem.value = null
      editedNotes.value = ''
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to update notes')
    }
  } catch (error) {
    console.error('Error updating notes:', error)
    error.value = error.message
  } finally {
    loading.value = false
  }
}

// Update fungsi close untuk membersihkan state dengan benar
const closeNotesDialog = () => {
  showNotesDialog.value = false
  selectedItem.value = null
  editedNotes.value = ''
  refreshTrigger.value++ // Trigger refresh
}

// Helper function to format date string to time
const formatTimeFromDate = (dateString) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
}

const getCurrentTime = () => {
  const now = new Date()
  // Format untuk memastikan 2 digit (HH:mm)
  return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`
}

const getEndTime = (startTime) => {
  const [hours, minutes] = startTime.split(':').map(Number)
  const endDate = new Date()
  endDate.setHours(hours + 1, minutes)
  
  return `${String(endDate.getHours()).padStart(2, '0')}:${String(endDate.getMinutes()).padStart(2, '0')}`
}

// Update openEstimationDialog function
const openEstimationDialog = (item) => {
  selectedItem.value = item
  
  // Check if item has existing estimation times
  if (item.estimasi_mulai && item.estimasi_selesai) {
    editedEstimation.value = {
      mulai: formatTimeFromDate(item.estimasi_mulai),
      selesai: formatTimeFromDate(item.estimasi_selesai)
    }
  } else {
    // If no existing times, use current time and current time + 1 hour
    const currentTime = getCurrentTime()
    editedEstimation.value = {
      mulai: currentTime,
      selesai: getEndTime(currentTime)
    }
  }
  
  showEstimationDialog.value = true
}

// Sama untuk estimation dialog
const saveEstimation = async () => {
  if (!selectedItem.value) return

  try {
    loading.value = true
    const response = await apiClient.put(`/web/lead-time/${selectedItem.value.id}/estimation`, {
      jsonrpc: "2.0",
      method: "call",
      params: {
        estimasi_mulai: editedEstimation.value.mulai,
        estimasi_selesai: editedEstimation.value.selesai
      }
    })

    if (response?.data?.result?.status === 'success') {
      refreshTrigger.value++ // Trigger refresh
      showEstimationDialog.value = false
      selectedItem.value = null
      editedEstimation.value = { mulai: '', selesai: '' }
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to update estimation times')
    }
  } catch (error) {
    console.error('Error updating estimation:', error)
    error.value = error.message
  } finally {
    loading.value = false
  }
}

const closeEstimationDialog = () => {
  showEstimationDialog.value = false
  selectedItem.value = null
  editedEstimation.value = { mulai: '', selesai: '' }
  refreshTrigger.value++ // Trigger refresh
}

// === JOB CONTROL STATE (Diperbarui) ===
const showActionDialog = ref(false)
const selectedRow = ref(null)
const selectedJobStop = ref(null)
const jobStopNote = ref('')
const isModalOpen = ref(false) // Tambahkan state untuk kontrol modal


const filters = [
  { label: 'Semua', value: 'all' },
  { label: 'Proses', value: 'proses' },
  { label: 'Selesai', value: 'selesai' },
  { label: 'Tunggu Part', value: 'tunggu_part' }
]

// Methods
// Methods
const updateClock = () => {
  const now = new Date()
  currentTime.value = new Intl.DateTimeFormat('id-ID', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Jakarta'
  }).format(now) + ' WIB'
}

const formatEstimatedTime = (dateString) => {
  if (!dateString) return '-'
  try {
    const date = new Date(dateString)
    date.setHours(date.getHours())
    
    return new Intl.DateTimeFormat('id-ID', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      // second: '2-digit',
      hour12: false
    }).format(date)
  } catch (err) {
    return '-'
  }
}

const formatNumber = (value) => {
  return value?.toString().padStart(3, '0') || '0';
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(value);
};

const formatDate = (dateString) => {
  if (!dateString) return '-';
  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('id-ID', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  } catch (err) {
    return dateString;
  }
};

// Computed untuk available job stops
// Update availableJobStops computed untuk better sublet handling
const availableJobStops = computed(() => {
  if (!selectedRow.value) return [];
  
  const jobStops = selectedRow.value.job_stops || {};
  
  // Helper untuk mengecek completion dan active state
  const getStopState = (type) => {
    const stop = jobStops[type];
    return {
      completed: stop?.completed || false,
      active: stop?.active || false
    };
  };

  

  // Get current states
  const subletState = getStopState('tunggu_sublet');
  const part1State = getStopState('tunggu_part');
  const part2State = getStopState('tunggu_part_2');
  
  return [
    {
      value: JOB_STOP_TYPES.TUNGGU_KONFIRMASI,
      label: 'Tunggu Konfirmasi',
      description: 'Menunggu konfirmasi dari customer',
      disabled: getStopState('tunggu_konfirmasi').completed || getStopState('tunggu_konfirmasi').active
    },
    {
      value: JOB_STOP_TYPES.TUNGGU_PART,
      label: 'Tunggu Part 1',
      description: 'Menunggu ketersediaan spare part (pertama)',
      disabled: part1State.completed || part1State.active
    },
    {
      value: JOB_STOP_TYPES.TUNGGU_PART_2,
      label: 'Tunggu Part 2',
      description: 'Menunggu ketersediaan spare part (kedua)',
      disabled: !part1State.completed || part2State.completed || part2State.active
    },
    {
      value: JOB_STOP_TYPES.TUNGGU_SUBLET,
      label: 'Tunggu Sublet',
      description: 'Menunggu pekerjaan sublet',
      disabled: subletState.completed || subletState.active
    },
    {
      value: JOB_STOP_TYPES.ISTIRAHAT,
      label: 'Istirahat',
      description: 'Waktu istirahat mekanik',
      disabled: getStopState('istirahat').completed || getStopState('istirahat').active
    },
    {
      value: JOB_STOP_TYPES.JOB_STOP_LAIN,
      label: 'Job Stop Lain',
      description: 'Penghentian sementara karena alasan lain yang perlu dicatat',
      disabled: jobStops.job_stop_lain?.completed || jobStops.job_stop_lain?.active
    }
  ];
});

// Tambahkan computed property untuk mengecek status
const isJobActive = (item) => {
  // Check all possible pause conditions
  if (item.status?.code === 'tunggu_konfirmasi' || 
      item.job_stops?.tunggu_konfirmasi?.active ||
      item.job_stops?.tunggu_part?.active ||
      item.job_stops?.tunggu_part_2?.active ||
      item.job_stops?.tunggu_sublet?.active ||  // Add sublet check
      item.job_stops?.istirahat?.active ||
      item.job_stops?.job_stop_lain?.active) {
    return false;
  }
  
  // Return true only if in progress
  return item.status?.code === 'in_progress' || 
         item.status?.code === 'proses';
}




// === METHODS (Diperbarui) ===
const resetJobControlState = () => {
  selectedJobStop.value = null;
  jobStopNote.value = '';
  selectedRow.value = null;
  isModalOpen.value = false;
};

// Modifikasi handleModalClose untuk menunggu fetch selesai
// Update handleModalClose
const handleModalClose = () => {
  showActionDialog.value = false
  resetJobControlState()
  refreshTrigger.value++ // Trigger refresh
}



// Update getStatusText
// Update getStatusText untuk include tunggu_sublet
const getStatusText = (item) => {
  if (!item?.status) return STATUS_TEXT_MAP[JOB_STATUS.NOT_STARTED]
  
  const baseText = STATUS_TEXT_MAP[item.status.code] || STATUS_TEXT_MAP[JOB_STATUS.NOT_STARTED]
  
  // Check active job stops
  if (item.job_stops) {
    if (item.job_stops.tunggu_sublet?.active) {  // Add sublet check early
      return `${baseText} (${formatJobStopType(JOB_STOP_TYPES.TUNGGU_SUBLET)})`
    }
    if (item.job_stops.tunggu_part_2?.active) {
      return `${baseText} (${formatJobStopType(JOB_STOP_TYPES.TUNGGU_PART_2)})`
    }
    if (item.job_stops.tunggu_part?.active) {
      return `${baseText} (${formatJobStopType(JOB_STOP_TYPES.TUNGGU_PART)})`
    }
    if (item.job_stops.tunggu_konfirmasi?.active) {
      return `${baseText} (${formatJobStopType(JOB_STOP_TYPES.TUNGGU_KONFIRMASI)})`
    }
    if (item.job_stops.istirahat?.active) {
      return `${baseText} (${formatJobStopType(JOB_STOP_TYPES.ISTIRAHAT)})`
    }
    if (item.job_stops.job_stop_lain?.active) {
      return `${baseText} (${formatJobStopType(JOB_STOP_TYPES.JOB_STOP_LAIN)})`
    }
  }
  
  return baseText
}

// Update getStatusClass untuk handle tunggu_sublet
const getStatusClass = (item) => {
  if (!item?.status) return STATUS_CLASS_MAP[JOB_STATUS.NOT_STARTED]
  
  if (item.job_stops) {
    if (item.job_stops.tunggu_sublet?.active) return STATUS_CLASS_MAP[JOB_STATUS.PAUSED]  // Add sublet check
    if (item.job_stops.tunggu_part_2?.active) return STATUS_CLASS_MAP[JOB_STATUS.PAUSED]
    if (item.job_stops.tunggu_part?.active) return STATUS_CLASS_MAP[JOB_STATUS.PAUSED]
    if (item.job_stops.tunggu_konfirmasi?.active) return STATUS_CLASS_MAP[JOB_STATUS.PAUSED]
    if (item.job_stops.istirahat?.active) return STATUS_CLASS_MAP[JOB_STATUS.PAUSED]
    if (item.job_stops.job_stop_lain?.active) return STATUS_CLASS_MAP[JOB_STATUS.PAUSED]
  }
  
  return STATUS_CLASS_MAP[item.status.code] || STATUS_CLASS_MAP[JOB_STATUS.NOT_STARTED]
}

// === JOB CONTROL METHODS (Diperbarui) ===
const handleJobStatusChange = async (value, item) => {
  console.log('Job status change:', { value, item });
  resetJobControlState();
  
  // Not started handler
  if (item.status?.code === 'not_started') {
    selectedRow.value = {
      ...item,
      jobStatus: JOB_STATUS.NOT_STARTED,
      currentJobStopType: null
    };
    if (value) {
      showActionDialog.value = true;
    }
    return;
  }
  
  // Set job status dan stop type
  let currentJobStatus;
  let currentStopType;
  
  // Check all possible stop types
  if (item.job_stops?.tunggu_sublet?.active) {  // Add sublet check early
    currentJobStatus = JOB_STATUS.PAUSED;
    currentStopType = JOB_STOP_TYPES.TUNGGU_SUBLET;
  } else if (item.job_stops?.tunggu_part_2?.active) {
    currentJobStatus = JOB_STATUS.PAUSED;
    currentStopType = JOB_STOP_TYPES.TUNGGU_PART_2;
  } else if (item.job_stops?.tunggu_part?.active) {
    currentJobStatus = JOB_STATUS.PAUSED;
    currentStopType = JOB_STOP_TYPES.TUNGGU_PART;
  } else if (item.job_stops?.tunggu_konfirmasi?.active) {
    currentJobStatus = JOB_STATUS.PAUSED;
    currentStopType = JOB_STOP_TYPES.TUNGGU_KONFIRMASI;
  } else if (item.job_stops?.istirahat?.active) {
    currentJobStatus = JOB_STATUS.PAUSED;
    currentStopType = JOB_STOP_TYPES.ISTIRAHAT;
  } else if (item.job_stops?.job_stop_lain?.active) {
    currentJobStatus = JOB_STATUS.PAUSED;
    currentStopType = JOB_STOP_TYPES.JOB_STOP_LAIN;
  } else if (item.status?.code === 'proses' || 
             item.status?.code === 'in_progress') {
    currentJobStatus = JOB_STATUS.IN_PROGRESS;
    currentStopType = null;
  } else {
    currentJobStatus = JOB_STATUS.NOT_STARTED;
    currentStopType = null;
  }
  
  selectedRow.value = {
    ...item,
    jobStatus: currentJobStatus,
    currentJobStopType: currentStopType
  };

  // Only show dialog when status actually changes
  if (value !== isJobActive(item)) {
    showActionDialog.value = true;
  }
};


// Update selectJobStop
const selectJobStop = (option) => {
  if (option.disabled) {
    return;
  }
  
  console.log('Selected job stop:', option.value); // Debugging
  selectedJobStop.value = option.value;
  
  // Reset note jika bukan job stop lain
  if (option.value !== JOB_STOP_TYPES.JOB_STOP_LAIN) {
    jobStopNote.value = '';
  }
};

// Update getDisabledReason to include time info
const getDisabledReason = (option) => {
  const jobStops = selectedRow.value?.job_stops || {};
  const stop = jobStops[option.value];
  
  if (option.value === JOB_STOP_TYPES.TUNGGU_PART_2 && !jobStops.tunggu_part?.completed) {
    return 'Selesaikan Tunggu Part 1 terlebih dahulu';
  }
  
  if (stop?.completed) {
    return `Sudah digunakan (${formatTimeRange(stop.start, stop.end)})`;
  }
  
  if (stop?.active) {
    return `Sedang berlangsung (sejak ${formatTimeOnly(stop.start)})`;
  }
  
  return 'Tidak tersedia';
};

const startNewJob = async () => {
  if (!selectedRow.value) return
  
  try {
    loading.value = true
    const response = await apiClient.post('/web/lead-time/start-service', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        order_id: selectedRow.value.id,
        type: 'new'
      }
    })
    
    if (response?.data?.result?.status === 'success') {
      toast.success('Servis berhasil dimulai')
      handleModalClose(); // Close modal after refreshing data
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to start service');
    }
  } catch (error) {
    console.error('Error starting job:', error)
    toast.error(`Gagal memulai servis: ${error.message}`)
    error.value = error.message
  } finally {
    loading.value = false
  }
}

const pauseJob = async () => {
  if (!selectedRow.value || !selectedJobStop.value) return;
  
  try {
    loading.value = true;
    
    // Validate job stop availability
    const validation = isJobStopAvailable(
      selectedRow.value.job_stops, 
      selectedJobStop.value
    );
    
    if (!validation.available) {
      throw new Error(validation.reason);
    }
    
    // Log untuk debugging
    console.log('Sending pause request with stop type:', selectedJobStop.value);
    
    const response = await apiClient.post('/web/lead-time/pause-service', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        order_id: selectedRow.value.id,
        stop_type: selectedJobStop.value,
        note: selectedJobStop.value === JOB_STOP_TYPES.JOB_STOP_LAIN 
          ? jobStopNote.value 
          : undefined
      }
    });
    
    if (response?.data?.result?.status === 'success') {
      toast.success(`Job stop ${formatJobStopType(selectedJobStop.value)} berhasil diterapkan`)
      handleModalClose();
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to pause service');
    }
  } catch (error) {
    console.error('Error pausing job:', error);
    toast.error(`Gagal menerapkan job stop: ${error.message}`);
    error.value = error.message;
  } finally {
    loading.value = false;
  }
};

// Update resumeJob untuk menangani status setelah resume
const resumeJob = async () => {
  if (!selectedRow.value) return;
  
  try {
    loading.value = true;
    const response = await apiClient.post('/web/lead-time/resume-service', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        order_id: selectedRow.value.id,
        stop_type: selectedRow.value.currentJobStopType
      }
    });
    
    if (response?.data?.result?.status === 'success') {
      toast.success('Servis berhasil dilanjutkan')
      handleModalClose(); // Close modal after resuming job
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to resume service');
    }
  } catch (error) {
    console.error('Error resuming job:', error);
    toast.error(`Gagal melanjutkan servis: ${error.message}`);
    error.value = error.message;
  } finally {
    loading.value = false;
  }
};

// / Update watch untuk dialog
// Watch refreshTrigger untuk melakukan fetch data
watch(refreshTrigger, async () => {
  try {
    await fetchData()
  } catch (error) {
    console.error('Error refreshing data:', error)
  }
})

// Method untuk complete service
const completeService = async () => {
  try {
    loading.value = true;
    const response = await apiClient.post('/web/lead-time/complete-service', {
      jsonrpc: "2.0",
      method: "call",
      params: {
        order_id: selectedRow.value.id
      }
    });
    
    if (response?.data?.result?.status === 'success') {
      toast.success('Servis sudah selesai');
      handleModalClose();
    } else {
      throw new Error(response?.data?.result?.message || 'Failed to complete service');
    }
  } catch (error) {
    console.error('Error completing service:', error);
    toast.error(`Gagal menyelesaikan servis: ${error.message}`);
    error.value = error.message;
  } finally {
    loading.value = false;
  }
};

// Update fetchData untuk memastikan selalu mendapat data terbaru
const fetchData = async (params = {}) => {
  if (loading.value) return;
  
  try {
    loading.value = true;
    error.value = null;

    const payload = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        page: params.page || 1,
        limit: params.limit || 20,
        filter: currentFilter.value,
        search_query: searchQuery.value,
        sort_by: params.sort_by || "id",
        sort_order: params.sort_order || "desc",
        _t: Date.now() // Tambahkan timestamp untuk mencegah caching
      }
    };
    
    const { data } = await apiClient.post('/web/lead-time/table', payload);
    
    if (!data?.result?.result?.status === 'success') {
      throw new Error('Invalid response format or operation failed');
    }

    const responseData = data.result.data;
    
    if (responseData) {
      rows.value = responseData.rows || [];
      summary.value = responseData.summary || {};
      pagination.value = responseData.pagination || {};
      currentDate.value = responseData.current_date || '';

      rows.value = (responseData.rows || []).map(row => ({
        ...row,
        isJobActive: row.status?.code === 'proses',
        jobStatus: row.status?.code === 'proses' ? JOB_STATUS.IN_PROGRESS : 
                  row.status?.code === 'tunggu_part' ? JOB_STATUS.PAUSED : 
                  JOB_STATUS.NOT_STARTED,
        currentJobStopType: row.status?.code === 'tunggu_part' ? JOB_STOP_TYPES.TUNGGU_PART : null
      }));
    }

  } catch (err) {
    console.error('Fetch error:', err);
    error.value = 'Gagal memuat data. Silakan coba lagi.';
    resetState();
  } finally {
    loading.value = false;
  }
};

// Versi sederhana dari canToggleJob
const canToggleJob = (item) => {
  // Hanya cek apakah servis sudah selesai
  return !item.timestamps?.selesai_servis;
}

const resetState = () => {
  rows.value = [];
  summary.value = null;
  pagination.value = null;
  currentDate.value = '';
};

const handleSearch = debounce(() => {
  fetchData({ page: 1 });
}, 300);

const handleFilterChange = (filter) => {
  currentFilter.value = filter;
  fetchData({ page: 1 });
};

const handlePageChange = (page) => {
  if (page === pagination.value?.current_page) return;
  fetchData({ page });
};

const formatDuration = (startTime, endTime = null) => {
  if (!startTime) return '';
  
  const start = new Date(startTime);
  const end = endTime ? new Date(endTime) : new Date();
  const diffInSeconds = Math.floor((end - start) / 1000);
  
  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  
  if (hours > 0) {
    return `${hours} jam ${minutes} menit`;
  }
  return `${minutes} menit`;
};

const TotalDuration = (timestamps) => {
  if (!timestamps?.mulai_servis) return '';
 
  const start = new Date(timestamps.mulai_servis);
  const end = timestamps.selesai_servis ? new Date(timestamps.selesai_servis) : new Date();
  const diffInSeconds = Math.floor((end - start) / 1000);
 
  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
 
  if (hours > 0) {
    return `${hours} jam ${minutes} menit`;
  }
  return `${minutes} menit`;
};

const formatDateTime = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString('id-ID', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const formatTimeOnly = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleTimeString('id-ID', {
    hour: '2-digit',
    minute: '2-digit'
  });
};

const getActiveJobStopTime = (item) => {
  if (!item.job_stops) return null;
  
  for (const [type, stop] of Object.entries(item.job_stops)) {
    if (stop.active) {
      const duration = formatDuration(stop.start);
      return `Mulai: jam ${formatTimeOnly(stop.start)} (${duration})`;
    }
  }
  return null;
};

const getJobStopHistory = (item) => {
  if (!item.job_stops) return [];
  
  const history = [];
  for (const [type, stop] of Object.entries(item.job_stops)) {
    if (stop.completed) {
      history.push({
        type,
        start: stop.start,
        end: stop.end
      });
    }
  }
  
  // Sort by start time, most recent first
  return history.sort((a, b) => new Date(b.start) - new Date(a.start));
};

const formatJobStopHistoryTime = (history) => {
  const duration = formatDuration(history.start, history.end);
  return `${formatTimeOnly(history.start)} - ${formatTimeOnly(history.end)} (${duration})`;
};

const getJobStopDuration = (item) => {
  if (!item.job_stops) return '';
  
  for (const stop of Object.values(item.job_stops)) {
    if (stop.active) {
      const duration = formatDuration(stop.start);
      return `Durasi: ${duration}`;
    }
  }
  return '';
};

// Add these new helper functions
const getJobStopInfo = (stopType) => {
  if (!selectedRow.value?.job_stops) return null;
  
  const stop = selectedRow.value.job_stops[stopType];
  if (!stop) return null;
  
  if (stop.completed) {
    return `Terakhir digunakan: ${formatTimeRange(stop.start, stop.end)}`;
  }
  
  return null;
};

const formatTimeRange = (start, end) => {
  const startTime = formatTimeOnly(start);
  const endTime = end ? formatTimeOnly(end) : 'sekarang';
  return `${startTime} - ${endTime}`;
};

const getCompletedJobStops = computed(() => {
  if (!selectedRow.value?.job_stops) return [];
  
  return Object.entries(selectedRow.value.job_stops)
    .filter(([_, stop]) => stop.completed)
    .map(([type, stop]) => ({
      type,
      start: stop.start,
      end: stop.end
    }))
    .sort((a, b) => new Date(b.end) - new Date(a.end));  // Sort by end time, newest first
});

const hasJobStopHistory = computed(() => {
  return getCompletedJobStops.value.length > 0;
});

// Add new helper functions
const formatShortDuration = (start, end) => {
  if (!start || !end) return '';
  const duration = formatDuration(start, end);
  return duration.replace(' jam', 'j').replace(' menit', 'm');
};

const getJobStopLastUsed = (stopType) => {
  const jobStops = selectedRow.value?.job_stops || {};
  const stop = jobStops[stopType];
  
  if (stop?.completed) {
    return {
      start: stop.start,
      end: stop.end
    };
  }
  return null;
};

// Auto-refresh setup
const startAutoRefresh = () => {
  stopAutoRefresh() // Clear existing interval
  refreshInterval = setInterval(() => {
    if (!loading.value) {
      refreshTrigger.value++
    }
  }, 30000) // Refresh every 30 seconds
}

const stopAutoRefresh = () => {
  if (refreshInterval) {
    clearInterval(refreshInterval)
    refreshInterval = null
  }
}

// Lifecycle hooks
onMounted(() => {
  fetchData()
  startAutoRefresh()
  updateClock()
  
  // Start clock interval
  clockInterval = setInterval(updateClock, 1000)
})

onUnmounted(() => {
  stopAutoRefresh()
  if (clockInterval) {
    clearInterval(clockInterval)
  }
})
</script>

<style>
.group:hover .absolute {
  display: block;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.job-stop-card {
  @apply p-4 rounded-lg border transition-all duration-200;
}

.job-stop-card:not(:disabled):hover {
  @apply border-blue-200 bg-blue-50;
}

.job-stop-card.selected {
  @apply border-blue-500 bg-blue-50;
}

.job-stop-card:disabled {
  @apply opacity-50 cursor-not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.job-stop-history {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.job-stop-history::-webkit-scrollbar {
  width: 6px;
}

.job-stop-history::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.job-stop-history::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.job-stop-history::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Optional: Add some smooth transitions */
.job-stop-card {
  transition: all 0.2s ease;
}

.job-stop-card:hover:not(:disabled) {
  transform: translateY(-1px);
}

/* Ensure text doesn't wrap awkwardly */
.job-stop-info {
  white-space: nowrap;
}

/* Add subtle shadow on hover */
button:not(:disabled):hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}
/* Optional: Add smooth transitions */
.job-stop-card {
  transition: all 0.2s ease;
}

/* Ensure equal heights for cards */
button {
  height: 100%;
}

/* Add subtle hover effects */
button:not(:disabled):hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}
.dialog-panel {
  transition: max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
}

/* Add hover effect for close button */
.close-button {
  transition: transform 0.2s ease-in-out;
}

.close-button:hover {
  transform: scale(1.1);
}
</style>
